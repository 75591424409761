import { ProspectType } from "@common/types/prospectTypes";
import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useProspectUpdateMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: prospectApi.update,
    onSuccess: (_, updateRequestData) => {
      const { uuid } = updateRequestData;

      const prospectData = queryClient.getQueryData(
        prospectQueryKeys.retrieve(uuid)
      ) as ProspectType;

      // Optimistically set query data before invalidating to help slow networking
      queryClient.setQueryData(prospectQueryKeys.retrieve(uuid), {
        ...prospectData,
        ...updateRequestData,
      });

      queryClient.invalidateQueries({
        queryKey: prospectQueryKeys.retrieve(uuid),
      });
    },
  });

  return mutation;
};
