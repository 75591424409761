import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PriceScheduleBreakdownVisual } from "@portal/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/PriceScheduleBreakdownVisual";
import {
  StyledChartTier,
  StyledChartTime,
  borderRadius,
  heightSpacingWithMargin,
} from "@portal/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/PriceScheduleBreakdownVisual.styled";
import styled from "styled-components";

export const GenerationPriceScheduleBreakdownVisual = styled(
  PriceScheduleBreakdownVisual
)`
  ${StyledChartTime} {
    margin-left: -${heightSpacingWithMargin};

    @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
      margin-left: unset;
      top: -1rem;
    }
  }

  ${StyledChartTier} {
    &:first-child {
      border-radius: 0 ${borderRadius} 0 0;

      @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
        border-radius: 0 0 0 ${borderRadius};
      }
    }

    &:last-child {
      border-radius: 0 0 ${borderRadius} 0;

      &:after {
        margin-left: -${heightSpacingWithMargin};
      }

      @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
        border-radius: 0 0 ${borderRadius} 0;
      }
    }

    &.timeTop ${StyledChartTime} {
      right: -${heightSpacingWithMargin};

      @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
        right: auto;
      }
    }
  }
`;
