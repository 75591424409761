import {
  fileIsPdf,
  fileIsValidImage,
  fileIsValidSize,
  formatFileSize,
} from "@common/utils/fileHelpers";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhOutlineButton } from "@design-system/components/RhOutlineButton/RhOutlineButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as FileIcon } from "@design-system/icons/FileIcon.svg";
import { ReactComponent as GreenCheck } from "@design-system/icons/GreenCheck.svg";
import { ReactComponent as UploadIcon } from "@design-system/icons/UploadIcon.svg";
import { ReactComponent as XCloseIcon } from "@design-system/icons/XCloseIcon.svg";
import { rhSpacing } from "@design-system/theme/spacing";
import { useRhIntl } from "@portal/hooks/useRhIntl";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";

export interface UploadFormValues {
  file: FileList;
}

interface UploadFormProps {
  onSubmitSuccess: (values: UploadFormValues) => Promise<boolean>;
}

const buttonReset = css`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  position: absolute;
  right: 0;
`;

const ResetButton = styled.button`
  ${buttonReset}
  margin-left: auto;
`;

const FileInformationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rhSpacing(1)}px;
  position: relative;
`;
const StyledForm = styled.form`
  & > :first-child {
    margin-bottom: ${rhSpacing(1)}px;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${rhSpacing(1)}px;
  margin-bottom: ${rhSpacing(2)}px;
`;

const FileSize = styled(RhTypography)`
  &.MuiTypography-root {
    margin-right: ${rhSpacing(2)}px;
  }
`;

const FileName = styled(RhTypography)`
  &.MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledCloseIcon = styled(XCloseIcon)`
  vertical-align: middle;
`;

const StyledSuccessMessage = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_SUCCESS_MAIN};
    display: flex;
    flex-direction: row;
    gap: ${rhSpacing(1)}px;
  }
`;

export const UploadForm = ({ onSubmitSuccess }: UploadFormProps) => {
  const { t } = useRhIntl();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const flash = useRhFlash();
  const {
    handleSubmit,
    watch,
    register,
    resetField,
    clearErrors,
    setError,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<UploadFormValues>({
    defaultValues: {
      file: undefined,
    },
  });
  const [showSuccess, setShowSuccess] = useState(false);

  const file = watch("file") ? watch("file")[0] : null;

  const MAX_FILE_SIZE = 52428800; // 50 MB in bytes

  useEffect(() => {
    if (file) {
      // Clear success of previous upload if needed
      setShowSuccess(false);

      if (!(fileIsValidImage(file) || fileIsPdf(file))) {
        flash.error(t("FormUploadClaim.UploadForm.incorrectFileType"));
        setError("file", {
          message: t("FormUploadClaim.UploadForm.incorrectFileType"),
          type: "invalidFileType",
        });
        return;
      }

      if (!fileIsValidSize(file, MAX_FILE_SIZE)) {
        flash.error(t("FormUploadClaim.UploadForm.fileTooBig"));
        setError("file", {
          message: t("FormUploadClaim.UploadForm.fileTooBig"),
          type: "invalidFileSize",
        });
        return;
      }

      // Clear any pre-existing errors
      clearErrors("file");
    }
  }, [file]);

  // image/* will allow mobile upload from camera, heic supports iPhone images
  const acceptedFileTypes = "image/*,.pdf,.heic";

  // doing this allows us to pull the ref out and also use useRef from react
  // https://react-hook-form.com/faqs/#Howtosharerefusage
  const { ref, ...rest } = register("file");

  useEffect(() => {
    if (showSuccess) {
      reset();
    }
  }, [reset, showSuccess]);

  useEffect(() => {
    if (errors.file) {
      resetField("file", {
        keepError: true,
      });
    }
  }, [errors.file]);

  const formSubmission = (values: UploadFormValues) => {
    onSubmitSuccess(values).then((isSuccess) => {
      setShowSuccess(isSuccess);
    });
  };

  return (
    <StyledForm
      onSubmit={handleSubmit(formSubmission)}
      aria-label={t("FormUploadClaim.UploadForm.uploadTitle")}
    >
      <RhTypography variant="body2" fontWeight="Bold">
        {t("FormUploadClaim.UploadForm.uploadTitle")}
      </RhTypography>

      <ButtonContainer>
        <RhOutlineButton
          data-tracking-click={{
            event: "Customer uploading product addon claims form",
          }}
          color="primary"
          size="small"
          onClick={() => fileInputRef.current?.click()}
        >
          <UploadIcon
            aria-hidden
            style={{ marginRight: `${rhSpacing(1)}px` }}
          />
          {t("FormUploadClaim.UploadForm.upload")}
        </RhOutlineButton>
        <RhButton
          data-tracking-click={{
            event: "Customer submitting Product addon form claim",
          }}
          color="primary"
          size="small"
          type="submit"
          disabled={!file || isSubmitting || isSubmitted}
        >
          {t("FormUploadClaim.UploadForm.submit")}
        </RhButton>
      </ButtonContainer>

      {showSuccess && (
        <StyledSuccessMessage variant="body2">
          <GreenCheck aria-hidden />
          {t("FormUploadClaim.UploadForm.successfulUpload")}
        </StyledSuccessMessage>
      )}

      {errors.file && (
        <RhTypography variant="body2" color="error">
          {errors.file.message}
        </RhTypography>
      )}

      {file ? (
        <FileInformationContainer>
          <FileIcon />
          <FileName variant="body2" component="span" color="textPrimary">
            {file.name}
          </FileName>

          <FileSize variant="body2" component="span" color="textSecondary">
            ({formatFileSize(file)})
          </FileSize>
          <ResetButton
            type="button"
            onClick={() => resetField("file")}
            aria-label={t("FormUploadClaim.UploadForm.clearFile")}
          >
            <StyledCloseIcon color="red" height={12} width={12} />
          </ResetButton>
        </FileInformationContainer>
      ) : null}

      <HiddenInput
        {...rest}
        name="file"
        type="file"
        aria-label="Claim Form Field" // we need an aria label for a hidden field
        accept={acceptedFileTypes}
        ref={(e) => {
          ref(e);
          fileInputRef.current = e;
        }}
      />
    </StyledForm>
  );
};
