import { useAcquisitionCampaignFromQueryParam } from "@portal/hooks/useAcquisitionCampaignFromQueryParam";
import { EnrollIndexPage } from "@portal/pages/Enroll/EnrollIndexPage/EnrollIndexPage";
import {
  SIGN_UP_LEAD_GEN_SUB_PATHNAME,
  SIGN_UP_RESUME_SUB_PATHNAME,
  SignUpStepType,
  signUpBasePath,
} from "@portal/routes/routePaths";
import { selectVisitedSignUpPages } from "@portal/selectors/signUpSelectors";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

const deprecatedSteps = ["plans", "availability", "updates", "choose-utility"];

export const SignUpOutlet = () => {
  const { visitedSteps } = useSelector(selectVisitedSignUpPages);
  const location = useLocation();
  const basePath = signUpBasePath();

  const { pathname } = location;
  const subpath = pathname.replace(basePath, "").split("/");
  const requestedStep = subpath[1] as SignUpStepType;

  const isLeadGen =
    subpath[1] === SIGN_UP_LEAD_GEN_SUB_PATHNAME ||
    subpath[1] === SIGN_UP_RESUME_SUB_PATHNAME;
  const isVisitedStep = visitedSteps.includes(requestedStep as SignUpStepType);

  useAcquisitionCampaignFromQueryParam();

  if (deprecatedSteps.includes(requestedStep)) {
    return <EnrollIndexPage />;
  }

  if (isLeadGen || isVisitedStep) {
    return <Outlet />;
  }

  return <EnrollIndexPage />;
};
