import { formatCurrency } from "@common/utils/dataFormatters";
import { offerSnapshotFAQsDevicesTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsDevices/OfferSnapshotFAQsDevices.en.i18n";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React, { ReactNode } from "react";

interface OfferSnapshotDevicesFAQProps {
  offerSnapshot: OfferSnapshot;
}

interface DeviceFAQType {
  faqBody: { jsxElementKey: string; text: ReactNode }[];
  faqTitle: ReactNode;
  jsxElementKey: string;
}

export const useOfferSnapshotDevicesFaqs = (
  props: OfferSnapshotDevicesFAQProps
): DeviceFAQType[] => {
  const { offerSnapshot } = props;

  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFAQsDevicesAdjustFaqBody1,
    tOfferSnapshotFAQsDevicesAdjustFaqTitle,
    tOfferSnapshotFAQsDevicesCompatibilityFaqBody1,
    tOfferSnapshotFAQsDevicesCompatibilityFaqTitle,
    tOfferSnapshotFAQsDevicesCreditsFaqBody1,
    tOfferSnapshotFAQsDevicesCreditsFaqTitle,
    tOfferSnapshotFAQsDevicesHowToConnectDevicesTitle,
    tOfferSnapshotFAQsDevicesMaxControlTimesFaqsBody1,
    tOfferSnapshotFAQsDevicesMaxControlTimesFaqsTitle,
    tOfferSnapshotFAQsDevicesMonthlyBillCreditFaqBody1,
    tOfferSnapshotFAQsDevicesMonthlyBillCreditFaqTitle,
    tOfferSnapshotFAQsDevicesNotificationsFaqBody1,
    tOfferSnapshotFAQsDevicesNotificationsFaqTitle,
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody1,
    tOfferSnapshotFAQsDevicesProgramFaqBody1,
    tOfferSnapshotFAQsDevicesProgramFaqBody2,
    tOfferSnapshotFAQsDevicesProgramFaqBody3,
    tOfferSnapshotFAQsDevicesProgramFaqTitle,
  } = translate(offerSnapshotFAQsDevicesTranslations);

  const {
    tOfferSnapshotFAQsDevicesProgramFaqBody4,
    tOfferSnapshotFAQsDevicesHowToConnectDevicesBody1,
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqTitle,
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody2,
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody3,
  } = translateJsx({
    tOfferSnapshotFAQsDevicesHowToConnectDevicesBody1: {
      link: (message: ReactNode[]) => (
        <a
          href="https://www.gotrhythm.com/blog/energy-saving-tips/how-to-connect-your-smart-thermostat"
          rel="noreferrer"
          target="_blank"
        >
          {message}
        </a>
      ),
    },
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody2: {
      hours: "48",
    },
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody3: {
      events: "3",
    },
    tOfferSnapshotFAQsDevicesParticipationRequirementsFaqTitle: {
      credit: formatCurrency(
        offerSnapshot?.thermostatManagementMonthlyDiscount ?? ""
      ),
    },
    tOfferSnapshotFAQsDevicesProgramFaqBody4: {
      events: "3",
      hours: "48",
    },
  });

  return [
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesHowToConnectDevicesBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesHowToConnectDevicesTitle,
      jsxElementKey: "item-1",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody1,
        },
        {
          jsxElementKey: "body-2",
          text: tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody2,
        },
        {
          jsxElementKey: "body-3",
          text: tOfferSnapshotFAQsDevicesParticipationRequirementsFaqBody3,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesParticipationRequirementsFaqTitle,
      jsxElementKey: "item-2",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesProgramFaqBody1,
        },
        {
          jsxElementKey: "body-2",
          text: tOfferSnapshotFAQsDevicesProgramFaqBody2,
        },
        {
          jsxElementKey: "body-3",
          text: tOfferSnapshotFAQsDevicesProgramFaqBody3,
        },
        {
          jsxElementKey: "body-4",
          text: tOfferSnapshotFAQsDevicesProgramFaqBody4,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesProgramFaqTitle,
      jsxElementKey: "item-3",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesAdjustFaqBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesAdjustFaqTitle,
      jsxElementKey: "item-4",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesCompatibilityFaqBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesCompatibilityFaqTitle,
      jsxElementKey: "item-5",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesCreditsFaqBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesCreditsFaqTitle,
      jsxElementKey: "item-6",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesMonthlyBillCreditFaqBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesMonthlyBillCreditFaqTitle,
      jsxElementKey: "item-7",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesMaxControlTimesFaqsBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesMaxControlTimesFaqsTitle,
      jsxElementKey: "item-8",
    },
    {
      faqBody: [
        {
          jsxElementKey: "body-1",
          text: tOfferSnapshotFAQsDevicesNotificationsFaqBody1,
        },
      ],
      faqTitle: tOfferSnapshotFAQsDevicesNotificationsFaqTitle,
      jsxElementKey: "item-9",
    },
  ];
};
