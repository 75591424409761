import { UUIDType } from "@common/types/apiTypes";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useProspectUuid = () => {
  const { prospectUuid: prospectUuidPathParam } = useParams<{
    prospectUuid: UUIDType | undefined;
  }>();

  const {
    cookies: { rhProspectUuid },
    setCookie,
  } = useEnrollCookies();

  const prospectUuidState = useState<UUIDType | undefined>(
    prospectUuidPathParam || rhProspectUuid || undefined
  );

  const [finalProspectUuid] = prospectUuidState;

  useEffect(() => {
    if (finalProspectUuid) {
      const thirtyDaysFromNow = dayjs().add(30, "day").toDate();

      setCookie("rhProspectUuid", finalProspectUuid, {
        expires: thirtyDaysFromNow,
      });
    }
  }, [finalProspectUuid, setCookie]);

  return prospectUuidState;
};
