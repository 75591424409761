import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { offerSnapshotFAQsAmazonThermostatTranslations } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsAmazonThermostat/OfferSnapshotFAQsAmazonThermostat.en.i18n";
import { OfferSnapshotFAQsTitleWrapper } from "@portal/components/OfferSnapshotFAQs/OfferSnapshotFAQsTitleWrapper/OfferSnapshotFAQsTitleWrapper";
import { PortalAccordion } from "@portal/components/PortalAccordion/PortalAccordion.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import React, { ReactNode } from "react";

export const OfferSnapshotFAQsAmazonThermostat = () => {
  const { translate, translateJsx } = useTranslations();
  const {
    tOfferSnapshotFAQsAmazonThermostatAbout,
    tOfferSnapshotFAQsAmazonThermostatCanYou,
    tOfferSnapshotFAQsAmazonThermostatWhenWillIGet,
    tOfferSnapshotFAQsAmazonThermostatYouWillReceive,
    tOfferSnapshotFAQsAmazonThermostatYourSmart,
    tOfferSnapshotFAQsAmazonThermostatWillIReceive,
    tOfferSnapshotFAQsAmazonThermostatWhatHappens,
    tOfferSnapshotFAQsAmazonThermostatYouCanKeep,
    tOfferSnapshotFAQsAmazonThermostatWhoDoICall,
    tOfferSnapshotFAQsAmazonThermostatIAmOncor,
    tOfferSnapshotFAQsAmazonThermostatYouHaveToInstall,
  } = translate(offerSnapshotFAQsAmazonThermostatTranslations);

  const {
    tOfferSnapshotFAQsAmazonThermostatAbsolutely,
    tOfferSnapshotFAQsAmazonThermostatPleaseChat,
  } = translateJsx({
    tOfferSnapshotFAQsAmazonThermostatAbsolutely: {
      link: (message: ReactNode[]) => (
        <a
          href="https://www.gotrhythm.com/blog/energy-saving-tips/how-to-connect-your-smart-thermostat"
          rel="noreferrer"
          target="_blank"
        >
          {message}
        </a>
      ),
    },
    tOfferSnapshotFAQsAmazonThermostatPleaseChat: {
      supportmail: (message: ReactNode[]) => (
        <a href="mailto:support@gotrhythm.com">{message}</a>
      ),
    },
  });

  return (
    <OfferSnapshotFAQsTitleWrapper
      title={tOfferSnapshotFAQsAmazonThermostatAbout}
    >
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatCanYou}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatAbsolutely}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhenWillIGet}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYourSmart}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWillIReceive}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouWillReceive}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhatHappens}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouCanKeep}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatWhoDoICall}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatPleaseChat}
        </RhTypography>
      </PortalAccordion>
      <PortalAccordion>
        <summary>{tOfferSnapshotFAQsAmazonThermostatIAmOncor}</summary>
        <RhTypography variant="body1">
          {tOfferSnapshotFAQsAmazonThermostatYouHaveToInstall}
        </RhTypography>
      </PortalAccordion>
    </OfferSnapshotFAQsTitleWrapper>
  );
};
