import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal/api/premiseApi";
import { PremiseQueryKeys } from "@portal/constants/querykeys.constants";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseRenewalOfferSnapshotsQuery {
  enabled: boolean;
  premiseId: IdType;
}

export const usePremiseRenewalOfferSnapshotsQuery = ({
  enabled,
  premiseId,
}: UsePremiseRenewalOfferSnapshotsQuery) => {
  const query = useQuery({
    enabled,
    queryFn: () => premiseApi.allRenewableOfferSnapshots(premiseId),
    queryKey: PremiseQueryKeys.allRenewableOfferSnapshots(premiseId),
    select: (data) => {
      return {
        ...data,
        myAccountOfferSnapshots: data.myAccountOfferSnapshots.map(
          (offerSnapshot) => new OfferSnapshot(offerSnapshot)
        ),
        oneClickRenewalOfferSnapshot: data.oneClickRenewalOfferSnapshot
          ? new OfferSnapshot(data.oneClickRenewalOfferSnapshot)
          : null,
      };
    },
  });

  return query;
};
