import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const StyledBillTipsCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
`;

export const StyledBillTipsDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${CSSVariables.COLOR_GREY_100};
  }
`;

export const StyledTipsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  list-style: disc;
  padding: ${rhSpacingPx(2)};
  text-align: start;
`;
