import { offerSnapshotsApi } from "@portal/api/offerSnapshotsApi";
import { PricingQueryKeys } from "@portal/constants/querykeys.constants";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { OfferSnapshotsSearchOptions } from "@portal/types/offerSnapshotTypes";
import { useQuery } from "@tanstack/react-query";

interface OfferSnapshotsQueryOptions {
  enabled?: boolean;
  retry?: boolean;
}

interface OfferSnapshotsQueryProps {
  queryOptions?: OfferSnapshotsQueryOptions;
  searchOptions: OfferSnapshotsSearchOptions;
}

export const useOfferSnapshotsQuery = (props: OfferSnapshotsQueryProps) => {
  const { queryOptions: { enabled = true, retry = true } = {}, searchOptions } =
    props;

  const query = useQuery({
    enabled,
    queryFn: () => offerSnapshotsApi.list(searchOptions),
    queryKey: PricingQueryKeys.offerSnapshot.list(searchOptions),
    retry,
    select: (data) => {
      return {
        ...data,
        results: data.results.map((snapshot) => new OfferSnapshot(snapshot)),
      };
    },
  });

  return query;
};
