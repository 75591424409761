import { Brands } from "@design-system/brandTheme/brands";
import {
  EnergyTexasTheme,
  RhythmTheme,
} from "@design-system/brandTheme/brandTheme";

export const getBrandTheme = () => {
  if (import.meta.env.VITE_APP_BRAND === Brands.EnergyTexas) {
    return EnergyTexasTheme;
  }

  return RhythmTheme;
};
