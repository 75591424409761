import { isDeviceTouchScreen } from "@common/utils/isDeviceTouchScreen";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CompetitorPlanChart } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanChart";
import {
  getTopCompetitors,
  groupByCompetitorAndCalculateAverage,
} from "@portal/components/CompetitorPlanComparisonChart/helpers";
import { offerSnapshotLearnMoreComparisonChartTranslations } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreComparisonChart/OfferSnapshotLearnMoreComparisonChart.en.i18n";
import {
  StyledChartWrap,
  StyledInnerChartWrap,
} from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreComparisonChart/OfferSnapshotLearnMoreComparisonChart.styled";
import { useCompetitorPlansQuery } from "@portal/hooks/queries/useCompetitorPlans.query";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotLearnMoreComparisonChartProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreComparisonChart = (
  props: OfferSnapshotLearnMoreComparisonChartProps
) => {
  const { offerSnapshot } = props;
  const { translate } = useTranslations();

  const competitorPlansQuery = useCompetitorPlansQuery({
    searchOptions: {
      offerSnapshotId: offerSnapshot.uuid,
    },
  });

  if (competitorPlansQuery.isPending || competitorPlansQuery.isError) {
    return null;
  }

  const competitors = groupByCompetitorAndCalculateAverage(
    competitorPlansQuery.data.results
  );

  // Take the 4 most expensive because reasons
  const topCompetitors = getTopCompetitors({
    competitors,
    count: 4,
    offerSnapshot,
  });

  if (topCompetitors.length === 0) {
    return null;
  }

  const {
    tOfferSnapshotLearnMoreComparisonChartSubtitleDesktop,
    tOfferSnapshotLearnMoreComparisonChartSubtitleMobile,
    tOfferSnapshotLearnMoreComparisonChartTitle,
  } = translate(offerSnapshotLearnMoreComparisonChartTranslations);

  const repPriceComparisonSubtitle = isDeviceTouchScreen
    ? tOfferSnapshotLearnMoreComparisonChartSubtitleMobile
    : tOfferSnapshotLearnMoreComparisonChartSubtitleDesktop;

  return (
    <StyledChartWrap>
      <StyledInnerChartWrap>
        <RhTypography variant="h2">
          {tOfferSnapshotLearnMoreComparisonChartTitle}
        </RhTypography>
        <RhTypography color="textSecondary" variant="body1">
          {repPriceComparisonSubtitle}
        </RhTypography>
      </StyledInnerChartWrap>
      <CompetitorPlanChart
        offerSnapshot={offerSnapshot}
        competitorPlans={topCompetitors}
      />
    </StyledChartWrap>
  );
};
