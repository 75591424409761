import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import styled from "styled-components";

export const PlanContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
  justify-content: flex-start;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    justify-content: center;
    text-align: center;
  }
`;

export const MiniCard = styled(PortalCard)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: column;
  }
`;

export const RhythmLearnMoreButton = styled.button`
  background: 0;
  border: 0;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  display: inline-block;
  font-size: 14px;
  padding: 0;
  text-align: left;
  width: auto;

  &:hover {
    border-radius: 1000px;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    font-weight: ${FontWeight.Bold};
    padding: ${rhSpacingPx(1)} ${rhSpacingPx(2)};

    text-align: center;

    &:hover {
      background-color: ${CSSVariables.COLOR_GREY_50};
    }
  }
`;
