import { enrollOffersPath } from "@portal/routes/routePaths";
import {
  AcquisitionData,
  prospectFetch,
  setSignUpInfo,
} from "@portal/slices/signUpSlice";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export const SignUpLeadGen = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);

  const acquisition: AcquisitionData = {
    acquisitionCampaign: params.get("rh_campaign"),
    acquisitionContent: params.get("rh_content"),
    acquisitionMedium: params.get("rh_medium"),
    acquisitionSource: params.get("rh_source"),
    acquisitionTfn: params.get("tfn"),
  };
  const referralFromFriendCode = params.get("referralCode");
  const campaignSlug = params.get("rcid");

  // Expected pathname like: `/sign-up/lead-generation/{uuid}...`
  const uuid = pathname.split("/")[3];

  if (uuid) {
    dispatch(prospectFetch(uuid));
    dispatch(
      setSignUpInfo({
        acquisition,
        campaignSlug,
        referralFromFriendCode,
        visitedSteps: [],
      })
    );
  }

  return <Navigate to={enrollOffersPath(uuid)} replace />;
};
