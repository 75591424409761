import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { ajax } from "@common/services/ajax";
import {
  OneClickRenewalOfferSnapshotsResponseType,
  PaginationEndpointResponseType,
} from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { PaymentHistoryType } from "@common/types/premiseTypes";
import { UsageType } from "@common/types/usageTypes";
import { premiseUrls } from "@portal/api/premiseUrls";
import { Premise } from "@portal/models/Premise.model";
import { AccountSummaryType } from "@portal/types/accountSummaryTypes";
import { BillingStatusType } from "@portal/types/billingStatusTypes";
import {
  DeviceConnectionActivityType,
  DeviceDemandResponseActivityType,
  DevicesType,
} from "@portal/types/devicesTypes";
import { DocumentHistoryType } from "@portal/types/documentHistoryTypes";
import {
  EnergyInvoiceResponseType,
  InvoiceType,
} from "@portal/types/invoiceTypes";
import { PaymentExtensionType } from "@portal/types/paymentExtensionTypes";
import {
  CreatePaymentExtensionPayloadType,
  CreatePaymentExtensionResponseType,
  PaymentExtensionQualifiedResponseType,
  PaymentMethodType,
  PaymentMethodsResponseType,
} from "@portal/types/paymentMethodTypes";
import { PremiseType } from "@portal/types/premiseTypes";
import {
  BillForecastSummaryType,
  KwhUsageSummaryType,
  TemperatureSummaryType,
} from "@portal/types/usageTypes";

export const premiseApi = {
  accountSummary: (premiseId: IdType) =>
    ajax.get<AccountSummaryType>(premiseUrls.accountSummary(premiseId)),
  allRenewableOfferSnapshots: (premiseId: IdType) =>
    ajax.get<OneClickRenewalOfferSnapshotsResponseType>(
      premiseUrls.allRenewableOfferSnapshots(premiseId)
    ),
  attachedDevices: (premiseId: IdType) =>
    ajax.get<DevicesType>(premiseUrls.attachedDevices(premiseId)),
  billForecastSummary: (premiseId: IdType): Promise<BillForecastSummaryType> =>
    ajax.get(premiseUrls.billForecastSummary(premiseId)),
  billingStatus: (premiseId: IdType): Promise<BillingStatusType> =>
    ajax.get(premiseUrls.billingStatus(premiseId)),
  communicationPreferences: ({
    premiseId,
    paperless,
  }: {
    paperless: boolean;
    premiseId: IdType;
  }) =>
    ajax.post<null>(premiseUrls.communicationPreferences(premiseId), {
      paperless,
    }),
  documents: (premiseId: IdType): Promise<DocumentHistoryType[]> => {
    return ajax.get(premiseUrls.documents(premiseId));
  },
  energyInvoices: (premiseId: IdType): Promise<EnergyInvoiceResponseType[]> =>
    ajax.get(premiseUrls.energyInvoices(premiseId)),
  fetchPaymentMethod: ({
    premiseId,
    paymentMethodId,
  }: {
    paymentMethodId: string;
    premiseId: IdType;
  }): Promise<PaymentMethodType> => {
    return ajax.get(premiseUrls.paymentMethod(premiseId, paymentMethodId));
  },
  invoiceHistory: (
    premiseId: IdType
  ): Promise<PaginationEndpointResponseType<InvoiceType>> =>
    ajax.get(premiseUrls.invoiceHistory(premiseId)),
  kwhUsageDay: (
    premiseId: IdType,
    targetDate: string
  ): Promise<UsageType[]> => {
    return ajax.get(premiseUrls.kwhUsageDay(premiseId), {
      params: {
        targetDate,
      },
    });
  },
  kwhUsageSummary: (premiseId: IdType): Promise<KwhUsageSummaryType> => {
    return ajax.get(premiseUrls.kwhUsageSummary(premiseId));
  },
  paymentExtensions: ({
    premiseId,
    payload,
  }: {
    payload: CreatePaymentExtensionPayloadType;
    premiseId: IdType;
  }) => {
    return ajax.post<CreatePaymentExtensionResponseType>(
      premiseUrls.paymentExtensions(premiseId),
      payload
    );
  },
  paymentExtensionsActive: (premiseId: IdType) =>
    ajax.get<PaymentExtensionType>(
      premiseUrls.paymentExtensionsActive(premiseId)
    ),
  paymentExtensionsQualified: (premiseId: IdType) =>
    ajax.get<PaymentExtensionQualifiedResponseType>(
      premiseUrls.paymentExtensionsQualified(premiseId)
    ),
  paymentHistory: (
    premiseId: IdType
  ): Promise<PaginationEndpointResponseType<PaymentHistoryType>> => {
    return ajax.get(premiseUrls.paymentHistory(premiseId));
  },
  paymentMethods: async (premiseId: IdType) => {
    const response = await ajax.get<PaymentMethodsResponseType>(
      premiseUrls.paymentMethods(premiseId)
    );

    return {
      ...response,
      paymentMethods: response.paymentMethods.map(
        (paymentMethod) => new PaymentMethod(paymentMethod)
      ),
    };
  },
  renewContract: ({
    premiseId,
    offerSnapshotId,
    paperless,
    autopay,
    paymentMethodId,
  }: {
    autopay?: boolean;
    offerSnapshotId: IdType;
    paperless?: boolean;
    paymentMethodId?: string;
    premiseId: IdType;
  }) =>
    ajax.post<null>(premiseUrls.renewContract(premiseId), {
      autopay,
      offerSnapshotId,
      paperless,
      paymentMethodId,
    }),
  retrieve: async (premiseId: IdType): Promise<Premise> => {
    const response = await ajax.get<PremiseType>(
      premiseUrls.retrieve(premiseId)
    );

    return new Premise(response);
  },
  temperatureSummary: ({ premiseId }: { premiseId: IdType }) =>
    ajax.get<TemperatureSummaryType>(premiseUrls.temperatureSummary(premiseId)),
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceConnectionActivityType>>(
      premiseUrls.thermostatConnectionActivity({ premiseId }),
      {
        params: searchOptions,
      }
    ),
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      thermostatId: IdType;
    };
  }) =>
    ajax.get<PaginationEndpointResponseType<DeviceDemandResponseActivityType>>(
      premiseUrls.thermostatDemandResponseActivity({ premiseId }),
      {
        params: searchOptions,
      }
    ),
  updateAutoPay: ({
    premiseId,
    paymentMethodId,
    enabled,
  }: {
    enabled: boolean;
    paymentMethodId?: string;
    premiseId: IdType;
  }) =>
    ajax.post<null>(premiseUrls.updateAutoPay(premiseId), {
      enabled,
      paymentMethodId,
    }),
  updatePaymentMethod: ({
    premiseId,
    isOneTimePayment,
    paymentMethodId,
    setDefaultPaymentMethod,
  }: {
    isOneTimePayment: boolean;
    paymentMethodId: string;
    premiseId: IdType;
    setDefaultPaymentMethod: boolean;
  }) => {
    return ajax.put(premiseUrls.updatePaymentMethod(premiseId), {
      isOneTimePayment,
      paymentMethodId,
      setDefaultPaymentMethod,
    });
  },
};
