import { Order } from "@common/models/Order.model";
import { formatDateRange } from "@common/utils/dataFormatters";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CalendarIcon } from "@design-system/icons/CalendarIcon.svg";
import { ReactComponent as FlashyDollar } from "@design-system/icons/FlashyDollar.svg";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { myAccountYourPlanCardTranslations } from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.en.i18n";
import {
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
  StyledRhCard,
  StyledSection,
  StyledTitle,
} from "@portal/components/MyAccountYourPlanCard/MyAccountYourPlanCard.styled";
import { useFormattedFeatures } from "@portal/components/OfferFeatures/useFormattedFeatures";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { ActionType, MyAccountEvents } from "@portal/services/segment.service";
import React from "react";
import styled from "styled-components";

interface MyAccountOfferCardBodyProps {
  offerSnapshot: OfferSnapshot;
  order: Order;
}

const TextButton = styled.button`
  background: none;
  border: none;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const MyAccountOfferCardBody = ({
  order,
  offerSnapshot,
}: MyAccountOfferCardBodyProps) => {
  const { translate } = useTranslations();

  const { addModal } = useModals();
  const track = useTrackMyAccountEvents();

  const { tMyAccountYourPlanCardViewPriceBreakdown } = translate(
    myAccountYourPlanCardTranslations
  );

  const features = [
    {
      Icon: CalendarIcon,
      id: "orderTermLength",
      text: formatDateRange(order.startDate, order.endDate),
    },
    ...useFormattedFeatures(offerSnapshot),
  ];

  if (offerSnapshot.isTimeOfUse) {
    features.unshift({
      Icon: FlashyDollar,
      id: "energyBreakdown",
      text: (
        <TextButton
          type="button"
          onClick={() => {
            addModal({
              energyBreakdown: {
                offerSnapshotUuid: offerSnapshot.uuid,
              },
            });

            track({
              action: ActionType.click,
              event: MyAccountEvents.viewPriceBreakdown,
              label: "Opened view price breakdown modal",
            });
          }}
        >
          <RhTypography variant="body1">
            {tMyAccountYourPlanCardViewPriceBreakdown}
          </RhTypography>
        </TextButton>
      ),
    });
  }

  return (
    <StyledSection aria-labelledby="orderCardTitle">
      <StyledRhCard
        noShadow
        title={
          <>
            <StyledTitle>
              <RhTypography variant="h3" id="orderCardTitle">
                {order.title}
              </RhTypography>
            </StyledTitle>
            <RhTypography variant="body1" color="textSecondary">
              {order.description}
            </RhTypography>
          </>
        }
      >
        <StyledList dense>
          {features.map(({ Icon, text, id }) => (
            <StyledListItem key={id}>
              <StyledListItemIcon>
                <Icon />
              </StyledListItemIcon>
              <StyledListItemText primary={text} />
            </StyledListItem>
          ))}
        </StyledList>
      </StyledRhCard>
    </StyledSection>
  );
};

interface MyAccountOfferCardProps {
  order: Order;
}

export const MyAccountOfferCard = ({ order }: MyAccountOfferCardProps) => {
  const { data: offerSnapshot, isPending } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: order.offerSnapshotUuid,
  });

  if (isPending) {
    return (
      <RhCard>
        <RhCircularProgress />
      </RhCard>
    );
  }

  if (!offerSnapshot) {
    return null;
  }

  return <MyAccountOfferCardBody order={order} offerSnapshot={offerSnapshot} />;
};
