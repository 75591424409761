import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { offerSnapshotThermostatRequiredModalTranslations } from "@portal/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal.en.i18n";
import {
  StyledBrandIcon,
  StyledBrandIconContainer,
  StyledContainer,
  StyledTextButton,
  StyledThermostatRequirementsList,
  StyledTitle,
} from "@portal/components/OfferSnapshotThermostatRequiredModal/OfferSnapshotThermostatRequiredModal.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as Amazon } from "@portal/images/amazon.svg";
import { ReactComponent as Honeywell } from "@portal/images/honeywell.svg";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotThermostatRequiredModalProps {
  handleClose: () => void;
  offerSnapshot: OfferSnapshot;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
}

export const OfferSnapshotThermostatRequiredModal = (
  props: OfferSnapshotThermostatRequiredModalProps
) => {
  const { handleClose, onEnrollClick, offerSnapshot } = props;
  const { featureFlagClient } = useFeatureFlagClient();

  const { translate } = useTranslations();
  const {
    tOfferSnapshotThermostatRequiredModalTitle,
    tOfferSnapshotThermostatRequiredModalConfirm,
    tOfferSnapshotThermostatRequiredModalNevermind,
    tOfferSnapshotThermostatRequiredModalConnect,
    tOfferSnapshotThermostatRequiredModalImportant,
    tOfferSnapshotThermostatRequiredModalInstall,
  } = translate(offerSnapshotThermostatRequiredModalTranslations);

  const { portalDeviceManagementAmazon, portalDeviceManagementHoneywell } =
    featureFlagClient.getFlags([
      ["portalDeviceManagementAmazon", false],
      ["portalDeviceManagementHoneywell", false],
    ]);

  const { isThermostatIncluded } = offerSnapshot;

  return (
    <RhModal
      ariaCloseLabel="Close"
      ariaLabel="Modal"
      handleClose={handleClose}
      size={ModalWidths.sm}
    >
      <StyledContainer>
        {isThermostatIncluded ? (
          <>
            <StyledTitle>
              {tOfferSnapshotThermostatRequiredModalImportant}
            </StyledTitle>
            <StyledThermostatRequirementsList>
              <li>{tOfferSnapshotThermostatRequiredModalInstall}</li>
              <li>{tOfferSnapshotThermostatRequiredModalConnect}</li>
            </StyledThermostatRequirementsList>
          </>
        ) : (
          <>
            <StyledTitle>
              {tOfferSnapshotThermostatRequiredModalTitle}
            </StyledTitle>
            <StyledBrandIconContainer>
              {portalDeviceManagementAmazon.value ? (
                <StyledBrandIcon>
                  <Amazon title="Amazon" />
                </StyledBrandIcon>
              ) : null}
              {portalDeviceManagementHoneywell.value ? (
                <StyledBrandIcon>
                  <Honeywell title="Honeywell" />
                </StyledBrandIcon>
              ) : null}
            </StyledBrandIconContainer>
          </>
        )}
        <RhButton
          data-tracking-click={{
            event: isThermostatIncluded
              ? "Prospect selecting a thermostat included plan"
              : "Prospect selecting a thermostat required plan",
          }}
          onClick={() => {
            handleClose();
            return onEnrollClick(offerSnapshot);
          }}
        >
          {tOfferSnapshotThermostatRequiredModalConfirm}
        </RhButton>
        <StyledTextButton
          data-tracking-click={{
            event: isThermostatIncluded
              ? "Cancel selecting a thermostat included plan"
              : "Cancel selecting a thermostat required plan",
          }}
          onClick={handleClose}
        >
          {tOfferSnapshotThermostatRequiredModalNevermind}
        </StyledTextButton>
      </StyledContainer>
    </RhModal>
  );
};
