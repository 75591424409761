import { BillSummary } from "@portal/components/EnergyUsageSectionV2/BillSummary/BillSummary";
import { EnergyGraph } from "@portal/components/EnergyUsageSectionV2/EnergyGraph";
import {
  StyledEnergyUsageSectionCard,
  StyledEnergyUsageSectionHeader,
} from "@portal/components/EnergyUsageSectionV2/EnergyGraph.styles";
import { translations as EnergyUsageSectionV2Translations } from "@portal/components/EnergyUsageSectionV2/EnergyUsageSectionV2.en.i18n";
import { PortalCardTitle } from "@portal/components/PortalCard/PortalCard.styled";
import { usePremise } from "@portal/hooks/usePremise";
import { useTranslations } from "@portal/hooks/useTranslations";
import React from "react";

export const EnergyUsageSectionV2 = () => {
  const { translate } = useTranslations();
  const { tEnergyUsageSectionV2SectionTitle } = translate(
    EnergyUsageSectionV2Translations
  );

  const { premise } = usePremise();

  if (!premise?.meter?.isSmt || !premise?.currentOrder) {
    return null;
  }

  return (
    <StyledEnergyUsageSectionCard>
      <StyledEnergyUsageSectionHeader>
        <PortalCardTitle>{tEnergyUsageSectionV2SectionTitle}</PortalCardTitle>
      </StyledEnergyUsageSectionHeader>
      <BillSummary premise={premise} />
      <EnergyGraph premise={premise} />
    </StyledEnergyUsageSectionCard>
  );
};
