import { UUIDType } from "@common/types/apiTypes";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { RhBorderedDialog } from "@design-system/components/RhBorderedDialog/RhBorderedDialog";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { offerSnapshotLearnMoreTranslations } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore.en.i18n";
import { OfferSnapshotLearnMoreSkeleton } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore.skeleton";
import {
  LearnMoreContainer,
  LearnMoreFooter,
  LeftColumnContainer,
  OfferSnapshotLearnMoreHeader,
  SideBar,
  StyledFAQs,
} from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMore.styled";
import { OfferSnapshotLearnMoreComparisonChart } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreComparisonChart/OfferSnapshotLearnMoreComparisonChart";
import { OfferSnapshotLearnMoreFeatures } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreFeatures/OfferSnapshotLearnMoreFeatures";
import { OfferSnapshotLearnMoreLegalDocuments } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreLegalDocuments/OfferSnapshotLearnMoreLegalDocuments";
import { OfferSnapshotLearnMoreRateAndEstimate } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreRateAndEstimate/OfferSnapshotLearnMoreRateAndEstimate";
import { OfferSnapshotLearnMoreReasonsToBelieve } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreReasonsToBelieve/OfferSnapshotLearnMoreReasonsToBelieve";
import { OfferSnapshotLearnMoreUsageTable } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreUsageTable/OfferSnapshotLearnMoreUsageTable";
import { PortalPriceScheduleBreakdown } from "@portal/components/PortalPriceScheduleBreakdown/PortalPriceScheduleBreakdown";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React from "react";
import { useSelector } from "react-redux";

interface OfferSnapshotLearnMoreProps {
  handleClose: () => void;
  offerSnapshotUUID: UUIDType;
  onEnrollClick: (offerSnapshot: OfferSnapshot) => void;
  selectPlanCtaText: string;
}

export const OfferSnapshotLearnMore = (props: OfferSnapshotLearnMoreProps) => {
  const { translate } = useTranslations();
  const { addModal, removeModal } = useModals();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const { handleClose, onEnrollClick, offerSnapshotUUID, selectPlanCtaText } =
    props;

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: offerSnapshotUUID,
  });

  const {
    tOfferSnapshotLearnMoreEnergyPriceBreakdown,
    tOfferSnapshotLearnMoreEnergySolarPriceBreakdown,
  } = translate(offerSnapshotLearnMoreTranslations);

  if (offerSnapshotQuery.isPending) {
    return (
      <RhBorderedDialog open onClose={handleClose}>
        <OfferSnapshotLearnMoreSkeleton />
      </RhBorderedDialog>
    );
  }

  if (offerSnapshotQuery.isError) {
    handleClose();
    return null;
  }

  const { data: offerSnapshot } = offerSnapshotQuery;

  const { isTimeOfUse, isTimeOfUseGeneration } = offerSnapshot;

  const handleEnrollClick = () => {
    if (offerSnapshot.isThermostatOffer) {
      removeModal("offerSnapshotLearnMore");

      return addModal({
        offerSnapshotThermostatRequired: {
          offerSnapshot,
          onEnrollClick,
        },
      });
    }

    onEnrollClick(offerSnapshot);

    return handleClose();
  };

  return (
    <RhBorderedDialog open onClose={handleClose}>
      <LearnMoreContainer>
        <OfferSnapshotLearnMoreHeader>
          <RhTypography variant="h1">{offerSnapshot.title}</RhTypography>

          <RhTypography color="textSecondary" variant="body1">
            {offerSnapshot.longDescription(locale)}
          </RhTypography>
        </OfferSnapshotLearnMoreHeader>

        <SideBar>
          <OfferSnapshotLearnMoreRateAndEstimate
            offerSnapshot={offerSnapshot}
          />
          <OfferSnapshotLearnMoreFeatures offerSnapshot={offerSnapshot} />
          <RhButton
            data-tracking-click={{ event: "Prospect selected a plan" }}
            color="primary"
            fullWidth
            onClick={handleEnrollClick}
          >
            {selectPlanCtaText}
          </RhButton>
        </SideBar>

        <OfferSnapshotLearnMoreReasonsToBelieve offerSnapshot={offerSnapshot} />

        {/* Add content here for left column before FAQs */}
        <LeftColumnContainer>
          <OfferSnapshotLearnMoreUsageTable offerSnapshot={offerSnapshot} />
          {isTimeOfUse ? (
            <PortalPriceScheduleBreakdown
              offerSnapshot={offerSnapshot}
              id="price-schedule-breakdown"
              title={
                isTimeOfUseGeneration
                  ? tOfferSnapshotLearnMoreEnergySolarPriceBreakdown
                  : tOfferSnapshotLearnMoreEnergyPriceBreakdown
              }
            />
          ) : null}

          {!offerSnapshot.solarEligible ? (
            <OfferSnapshotLearnMoreComparisonChart
              offerSnapshot={offerSnapshot}
            />
          ) : null}
        </LeftColumnContainer>

        <OfferSnapshotLearnMoreLegalDocuments offerSnapshot={offerSnapshot} />
        <StyledFAQs offerSnapshot={offerSnapshot} />

        <LearnMoreFooter>
          <RhButton
            data-tracking-click={{ event: "Prospect selected a plan" }}
            color="primary"
            fullWidth
            onClick={handleEnrollClick}
          >
            {selectPlanCtaText}
          </RhButton>
        </LearnMoreFooter>
      </LearnMoreContainer>
    </RhBorderedDialog>
  );
};
