import {
  competitorPlanOfferSnapshotUUIDAtom,
  competitorPlanTermMonthsAtom,
} from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison.atoms";
import { competitorPlanComparisonTranslations } from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison.en.i18n";
import {
  BalancedText,
  Container,
  EmptyFlexItem,
  MiniCard,
  SelectContainer,
  Sidebar,
  StyledRhSelect,
  StyledRhSelectLabel,
} from "@portal/components/CompetitorPlanComparison/CompetitorPlanComparison.styled";
import { CompetitorPlanComparisonChart } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

interface CompetitorPlanComparisonProps {
  className?: string;
  id?: string;
  miniCardSubmitText: string;
  offerSnapshots: OfferSnapshot[];
  onLearnMoreClick: (offerSnapshot: OfferSnapshot) => void;
  onSelectPlanClick: (OfferSnapshot: OfferSnapshot) => void;
}

const getTermMonths = (offerSnapshots: OfferSnapshot[]) => {
  const termMonthsSet = new Set(
    offerSnapshots.map((offerSnapshot) => offerSnapshot.termMonths)
  );
  const sortedTermMonths = Array.from(termMonthsSet).sort((a, b) => a - b);

  // If we have 12 months make sure its in the front of the list
  if (sortedTermMonths.includes(12)) {
    sortedTermMonths.splice(sortedTermMonths.indexOf(12), 1);
    sortedTermMonths.unshift(12);
  }

  return sortedTermMonths;
};

export const CompetitorPlanComparison = (
  props: CompetitorPlanComparisonProps
) => {
  const { translate, translateJsx } = useTranslations();
  const {
    offerSnapshots,
    miniCardSubmitText,
    onLearnMoreClick,
    onSelectPlanClick,
    className,
    id,
  } = props;

  const [selectedTermMonths, setSelectedTermMonths] = useAtom(
    competitorPlanTermMonthsAtom
  );

  const [selectedOfferSnapshotUUID, setSelectedOfferSnapshotUUID] = useAtom(
    competitorPlanOfferSnapshotUUIDAtom
  );

  const eligibleOfferSnapshots = offerSnapshots.filter(
    (offerSnapshot) => !offerSnapshot.solarEligible
  );

  const termMonths = getTermMonths(eligibleOfferSnapshots);

  const {
    tCompetitorPlanComparisonMonthToMonth,
    tCompetitorPlanComparisonTitle,
    tCompetitorPlanComparisonSubTitle,
    tCompetitorPlanComparisonRhythmPlans,
    tCompetitorPlanComparisonTermLength,
  } = translate(competitorPlanComparisonTranslations);

  const viewableOfferSnapshots = eligibleOfferSnapshots.filter(
    (offerSnapshot) => offerSnapshot.termMonths === Number(selectedTermMonths)
  );

  useEffect(() => {
    if (selectedTermMonths && viewableOfferSnapshots.length > 0) {
      setSelectedOfferSnapshotUUID(viewableOfferSnapshots[0].uuid);
    }
  }, [selectedTermMonths, setSelectedOfferSnapshotUUID]);

  useEffect(() => {
    if (!selectedTermMonths && termMonths.length > 0) {
      setSelectedTermMonths(termMonths[0].toString());
    }
  }, [selectedTermMonths, setSelectedTermMonths, termMonths]);

  const selectedOfferSnapshot = eligibleOfferSnapshots.find(
    (offerSnapshot) => offerSnapshot.uuid === selectedOfferSnapshotUUID
  );

  return (
    <Container className={className} id={id}>
      <BalancedText variant="h2">{tCompetitorPlanComparisonTitle}</BalancedText>
      <BalancedText variant="subtitle2" color="textSecondary">
        {tCompetitorPlanComparisonSubTitle}
      </BalancedText>
      <Sidebar>
        <SelectContainer>
          <StyledRhSelectLabel>
            {tCompetitorPlanComparisonTermLength}
            <StyledRhSelect
              onChange={(event) =>
                setSelectedTermMonths(event.currentTarget.value)
              }
              value={selectedTermMonths}
            >
              {termMonths.map((termMonth) => {
                const { tCompetitorPlanComparisonXMonths } = translateJsx({
                  tCompetitorPlanComparisonXMonths: {
                    termMonths: termMonth,
                  },
                });
                const displayText =
                  termMonth === 1
                    ? tCompetitorPlanComparisonMonthToMonth
                    : tCompetitorPlanComparisonXMonths;

                return (
                  <option
                    value={termMonth}
                    key={`term-month-option-${termMonth}`}
                  >
                    {displayText}
                  </option>
                );
              })}
            </StyledRhSelect>
          </StyledRhSelectLabel>

          <StyledRhSelectLabel>
            {tCompetitorPlanComparisonRhythmPlans}
            <StyledRhSelect
              value={selectedOfferSnapshotUUID}
              onChange={(event) =>
                setSelectedOfferSnapshotUUID(event.currentTarget.value)
              }
            >
              {viewableOfferSnapshots.map((offerSnapshot) => {
                return (
                  <option value={offerSnapshot.uuid} key={offerSnapshot.id}>
                    {offerSnapshot.title}
                  </option>
                );
              })}
            </StyledRhSelect>
          </StyledRhSelectLabel>
        </SelectContainer>

        {selectedOfferSnapshot ? (
          <MiniCard
            offerSnapshot={selectedOfferSnapshot}
            submitButtonText={miniCardSubmitText}
            learnMoreButtonOnClick={() =>
              onLearnMoreClick(selectedOfferSnapshot)
            }
            submitButtonOnClick={() => {
              onSelectPlanClick(selectedOfferSnapshot);
            }}
          />
        ) : (
          <EmptyFlexItem />
        )}
      </Sidebar>

      {selectedOfferSnapshot ? (
        <CompetitorPlanComparisonChart
          offerSnapshot={selectedOfferSnapshot}
          key={selectedOfferSnapshot.id}
          onSelectPlanClick={onSelectPlanClick}
        />
      ) : null}
    </Container>
  );
};
