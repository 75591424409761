import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { OfferSnapshotFilterType } from "@portal/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.types";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { selectedOfferSnapshotFilterAtom } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPage.atoms";
import { customerPreferencesLanguageUpdated } from "@portal/slices/customerPreferencesSlice";
import { setSignUpInfo } from "@portal/slices/signUpSlice";
import { SupportedPortalLanguage } from "@portal/translations/portalTranslations";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useEnrollQueryParams = () => {
  const prospect = useProspectFromContext();
  const [searchParams] = useSearchParams();

  const { mutate } = useProspectUpdateMutation();

  const dispatch = useDispatch();

  const setSelectedOfferSnapshotFilter = useSetAtom(
    selectedOfferSnapshotFilterAtom
  );

  useEffect(() => {
    if (searchParams.size > 0) {
      const prospectFields = {
        acquisitionCampaign: searchParams.get("rh_campaign"),
        acquisitionContent: searchParams.get("rh_content"),
        acquisitionMedium: searchParams.get("rh_medium"),
        acquisitionSource: searchParams.get("rh_source"),
        featureFlagUserId: searchParams.get("featureFlagUserId"),
        rcid: searchParams.get("rcid"),
        zipCode: searchParams.get("zipcode"),
      };

      // Non prospect fields
      if (searchParams.get("term_months_tab")) {
        setSelectedOfferSnapshotFilter(
          searchParams.get("term_months_tab") as OfferSnapshotFilterType
        );
      }

      if (searchParams.get("locale")) {
        dispatch(
          customerPreferencesLanguageUpdated(
            searchParams.get("locale") as SupportedPortalLanguage
          )
        );
      }

      if (searchParams.get("appSource")) {
        dispatch(
          setSignUpInfo({
            appSource: searchParams.get("appSource"),
          })
        );
      }

      const filteredParamsObject = Object.fromEntries(
        Object.entries(prospectFields).filter(([, value]) => value !== null)
      );

      if (Object.entries(filteredParamsObject).length > 0) {
        mutate({
          data: filteredParamsObject,
          uuid: prospect.uuid,
        });
      }
    }
  }, [
    prospect.uuid,
    mutate,
    searchParams,
    setSelectedOfferSnapshotFilter,
    dispatch,
  ]);
};
