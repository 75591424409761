import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useLanguagePreference = () => {
  const {
    cookies: { django_language: djangoLanguageCookie },
    setCookie,
  } = usePortalCookies();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  useEffect(() => {
    if (locale !== djangoLanguageCookie) {
      const oneHunderEightyDaysFromNow = dayjs().add(180, "day").toDate();

      setCookie("django_language", locale, {
        expires: oneHunderEightyDaysFromNow,
      });
    }
  }, [djangoLanguageCookie, locale, setCookie]);
};
