import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useTranslations } from "@portal/hooks/useTranslations";
import { ReactComponent as GoogleIcon } from "@portal/images/GoogleIcon.svg";
import { SignInPageTranslations } from "@portal/pages/SignInPage/SignInPage.en.i18n";
import { portalAuthClient } from "@portal/services/PortalAuthClient.service";
import React from "react";
import styled from "styled-components";

const SignInWithGoogleButtonStyled = styled.button`
  align-items: center;
  background-color: transparent;
  border: 2px solid ${CSSVariables.COLOR_GREY_500};
  border-radius: 1000px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  line-height: 18px;
  margin-bottom: 1rem;
  padding: 7px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const SignInWithGoogleButton = () => {
  const { translate } = useTranslations();

  const { tSignInPageGoogleAuthCta } = translate(SignInPageTranslations);

  return (
    <SignInWithGoogleButtonStyled
      onClick={() => {
        portalAuthClient.signInWithGoogle();
      }}
    >
      <GoogleIcon height={32} width={32} />
      <RhTypography
        variant="subtitle2"
        color="textSecondary"
        fontWeight={FontWeight.Semibold}
      >
        {tSignInPageGoogleAuthCta}
      </RhTypography>
    </SignInWithGoogleButtonStyled>
  );
};
