import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { offerSnapshotsUrls } from "@portal/api/offerSnapshotsUrls";
import {
  OfferSnapshotType,
  OfferSnapshotsSearchOptions,
} from "@portal/types/offerSnapshotTypes";

export const offerSnapshotsApi = {
  list: (
    params: OfferSnapshotsSearchOptions
  ): Promise<PaginationEndpointResponseType<OfferSnapshotType>> => {
    return ajax.get(offerSnapshotsUrls.list(), {
      params,
    });
  },
  retrieve: (offerSnapshotUuid: IdType) =>
    ajax.get<OfferSnapshotType>(offerSnapshotsUrls.retrieve(offerSnapshotUuid)),
};
