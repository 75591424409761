import { useOktaAuth } from "@okta/okta-react";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { useGetCustomerRewards } from "@portal/hooks/rewardsHooks";
import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import { usePremise } from "@portal/hooks/usePremise";
import { useEffect } from "react";

export enum SwitchPrintToPaperlessModalStates {
  HIDDEN = "hidden",
  SHOW = "show",
}

export const useSwitchPrintToPaperlessModal = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated = false } = authState || {};
  const { premise } = usePremise();
  const { rewards } = useGetCustomerRewards();
  const { cookies } = usePortalCookies();

  const { rhSwitchPrintToPaperlessModal } = cookies;

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise?.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise?.currentOrder?.offerSnapshotUuid),
    },
  });

  const { addModal } = useModals();

  useEffect(() => {
    if (
      !isAuthenticated ||
      !premise ||
      !rewards ||
      !offerSnapshotQuery.isSuccess
    ) {
      return;
    }

    if (premise.isPrint && rhSwitchPrintToPaperlessModal === "show") {
      const hasPaperlessOrEBillReward = rewards.history.some(
        (reward) => reward.name === "eBill" || reward.name === "Paperless"
      );

      const offerSnapshot = offerSnapshotQuery.data;

      const showRewardsIncentive =
        !hasPaperlessOrEBillReward &&
        !offerSnapshot.isAutoPayPaperlessDiscountOffer;

      addModal({
        switchPrintToPaperless: {
          premiseId: premise.id,
          showRewardsIncentive,
        },
      });
    }
  }, [
    addModal,
    isAuthenticated,
    offerSnapshotQuery.isSuccess,
    premise,
    rewards,
    rhSwitchPrintToPaperlessModal,
  ]);

  return null;
};
