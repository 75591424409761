import { BillCostEstimator } from "@portal/components/BillCostEstimator/BillCostEstimator";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { BillCostEstimatorContainer } from "@portal/pages/Enroll/EnrollOffersPage/EnrollOffersPageBillCostEstimator/EnrollOffersPageBillCostEstimator.styled";
import React, { Dispatch, SetStateAction } from "react";

interface SignUpBillCostEstimatorProps {
  estimatedMonthlyUsage: number;
  id: string;
  offerSnapshots: OfferSnapshot[];
  setEstimatedMonthlyUsage: Dispatch<SetStateAction<number>>;
}

export const EnrollOffersPageBillCostEstimator = (
  props: SignUpBillCostEstimatorProps
) => {
  const {
    estimatedMonthlyUsage,
    setEstimatedMonthlyUsage,
    offerSnapshots,
    id,
  } = props;

  return (
    <BillCostEstimatorContainer>
      <BillCostEstimator
        onEstimatedMonthlyUsageChange={setEstimatedMonthlyUsage}
        estimatedMonthlyUsage={estimatedMonthlyUsage}
        offerSnapshots={offerSnapshots}
        id={id}
      />
    </BillCostEstimatorContainer>
  );
};
