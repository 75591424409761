import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledColumn = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    width: 50%;
  }
`;

export const SignInOptions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${rhSpacingPx(3)};
  max-width: 262px;
`;

export const StyledPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: ${rhSpacingPx(4)};
  width: 100%;

  > *:nth-child(2) {
    border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
    display: "flex";
  }

  > *:nth-child(3) {
    display: block;
  }

  @media (min-width: ${RhythmBreakpoints.MD}px) {
    margin-top: ${rhSpacingPx(6)};
    max-width: 80%;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;

    > ${StyledColumn}:first-child {
      border-right: 1px solid ${CSSVariables.COLOR_GREY_100};
    }

    > *:last-child {
      border-top: 0;
    }
  }
`;

export const StyledTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(3)};
  }
`;

export const StyledForgotPasswordLink = styled(Link)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: ${FontWeight.Medium};
  line-height: 1.7;
  text-decoration: none;
  text-transform: capitalize;
`;

export const Skyline = styled.div`
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    background-image: url("/assets/images/Skyline.svg");
    background-position: left -10vw bottom 70px;
    background-repeat: repeat-x;
    bottom: 0;
    height: 200px;
    left: 0;
    position: fixed;
    width: 100vw;
    z-index: -1;
  }
`;
