export const offerSnapshotFAQsAmazonThermostatTranslations = {
  tOfferSnapshotFAQsAmazonThermostatAbout: "About Amazon Thermostat",
  tOfferSnapshotFAQsAmazonThermostatAbsolutely:
    "Absolutely. Click <link>this</link> for detailed steps.",
  tOfferSnapshotFAQsAmazonThermostatCanYou:
    "Can you give me step-by-step instructions on how to connect my Amazon Smart Thermostat?",
  tOfferSnapshotFAQsAmazonThermostatIAmOncor:
    "I am an Oncor customer. How do I claim my $50 incentive from Oncor for installing a smart thermostat?",
  tOfferSnapshotFAQsAmazonThermostatPleaseChat:
    "Please chat or email our Customer Care Team at <supportmail>support@gotrhythm.com</supportmail> if you have any questions about your smart thermostat.",
  tOfferSnapshotFAQsAmazonThermostatWhatHappens:
    "What happens if I cancel my Rhythm Energy account?",
  tOfferSnapshotFAQsAmazonThermostatWhenWillIGet:
    "When will I get my Amazon Smart Thermostat?",
  tOfferSnapshotFAQsAmazonThermostatWhoDoICall:
    "Who do I call if I have issues with my smart thermostat?",
  tOfferSnapshotFAQsAmazonThermostatWillIReceive:
    "Will I receive any sort of tracking information or confirmation email?",
  tOfferSnapshotFAQsAmazonThermostatYouCanKeep:
    "You can keep your device if you cancel with Rhythm. If you cancel your electricity contract before your contract end date, you may be liable to pay a Device Cost Recovery Fee, listed in your EFL.",
  tOfferSnapshotFAQsAmazonThermostatYouHaveToInstall:
    "You have to install your Amazon Smart Thermostat and connect it to your Rhythm Account within 30 days of your service start date to claim your $50 incentive.",
  tOfferSnapshotFAQsAmazonThermostatYouWillReceive:
    "You will receive a delivery confirmation from Amazon once the smart thermostat is delivered.",
  tOfferSnapshotFAQsAmazonThermostatYourSmart:
    "Your smart thermostat will be shipped to you on your Rhythm contract start date and typically takes 3-5 business days to deliver depending on your location.",
};
