import { ProspectType } from "@common/types/prospectTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useCreateProspectMutation } from "@enroll-data/hooks/mutations/useCreateProspect.mutation";
import { useProspectQuery } from "@enroll-data/hooks/queries/useProspect.query";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { ProspectCreateRequest } from "@enroll-utils/types/prospectTypes";
import { googleAnalyticsDataAtom } from "@portal/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { useProspectUuid } from "@portal/components/ProspectProvider/useProspectUuid";
import { featureFlagUserIdAtom } from "@portal/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { Error500Page } from "@portal/pages/Error500Page/Error500Page";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { prospectReceived, setSignUpInfo } from "@portal/slices/signUpSlice";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

export const ProspectContext = createContext<ProspectType | null>(null);

interface ProspectProviderProps {}

const cleanProspectRequest = (
  obj: ProspectCreateRequest
): Partial<ProspectCreateRequest> => {
  const result: Partial<ProspectCreateRequest> =
    {} as Partial<ProspectCreateRequest>;

  Object.keys(obj).forEach((k: string) => {
    const key = k as keyof ProspectCreateRequest;
    const value = obj[key as keyof ProspectCreateRequest];

    if (value !== null && value !== undefined) {
      result[key] = value;
    }
  });

  return result;
};

interface ProspectProviderProps {
  createRetry?: number;
}

export const ProspectProvider = ({
  children,
  createRetry = 2,
}: PropsWithChildren<ProspectProviderProps>) => {
  const setFeatureFlagUserIdAtom = useSetAtom(featureFlagUserIdAtom);

  const prospectMutation = useCreateProspectMutation({
    mutationOptions: {
      retry: createRetry,
    },
  });
  const [prospectUuid, setProspectUuid] = useProspectUuid();
  const signUpState = useSelector(selectSignUpState);
  const [prospect, setProspect] = useState<ProspectType | null>(null);
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);
  const dispatch = useDispatch();
  const {
    cookies: { ajs_anonymous_id: segmentAnonIdCookie, rhFeatureFlagUserId },
    cookies,
  } = useEnrollCookies();

  useEffect(() => {
    if (prospectUuid) {
      setFeatureFlagUserIdAtom(prospectUuid);
    }
  }, [prospectUuid, setFeatureFlagUserIdAtom]);

  useEffect(() => {
    if (prospect) {
      setGoogleAnalyticsDataAtom({
        prospectOfferSnapshotUuid: prospect.offerSnapshotUuid || undefined,
        prospectUuid: prospect.uuid,
        type: "loggedOut",
      });
    } else {
      setGoogleAnalyticsDataAtom(RESET);
    }
  }, [prospect, setGoogleAnalyticsDataAtom]);

  useEffect(() => {
    if (
      prospectUuid ||
      Object.keys(cookies).length === 0 ||
      prospectMutation.isPending
    ) {
      return;
    }

    const prospectCreateBody: ProspectCreateRequest = {
      acquisitionCampaign: signUpState.acquisition.acquisitionCampaign,
      acquisitionContent: signUpState.acquisition.acquisitionContent,
      acquisitionMedium: signUpState.acquisition.acquisitionMedium,
      acquisitionSource: signUpState.acquisition.acquisitionSource,
      featureFlagUserId: rhFeatureFlagUserId || signUpState.featureFlagUserId,
      languagePreference: signUpState.languagePreference,
      rcid: signUpState.campaignSlug,
      referralCode: signUpState.referralFromFriendCode,
      segmentAnonId: segmentAnonIdCookie,
      zipCode: signUpState.serviceAddress.zipCode,
    };

    const cleanedProspectCreateBody = cleanProspectRequest(prospectCreateBody);

    prospectMutation.mutate(cleanedProspectCreateBody, {
      onSuccess: (_prospect: ProspectType) => {
        setProspectUuid(_prospect.uuid);
        dispatch(
          setSignUpInfo({
            prospectId: _prospect.id,
          })
        );
      },
    });
  }, [prospectUuid, cookies]);

  const prospectQuery = useProspectQuery(prospectUuid);

  useEffect(() => {
    if (prospectQuery.isError) {
      setProspectUuid(undefined);
    }
  }, [prospectQuery.isError, setProspectUuid]);

  useEffect(() => {
    if (prospectQuery.data) {
      setProspect(prospectQuery.data);
      const prospectData = prospectQuery.data;

      dispatch(
        prospectReceived({
          ...prospectQuery.data,
          autopay: Boolean(prospectData.autopay),
          eBillOnly: Boolean(prospectData.eBillOnly),
          phone: prospectData.phone || "",
        })
      );
    }
  }, [prospectQuery.data]);

  if (prospectMutation.isError) {
    return <Error500Page />;
  }

  if (
    prospectQuery.isError ||
    prospectQuery.isPending ||
    prospectMutation.isPending
  ) {
    return <RhCircularProgress />;
  }

  if (!prospectUuid) {
    return <RhCircularProgress />;
  }

  if (!prospect) {
    return <RhCircularProgress />;
  }

  return (
    <ProspectContext.Provider value={prospect}>
      {children}
    </ProspectContext.Provider>
  );
};
