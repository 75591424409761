import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import { offerSnapshotLearnMoreLegalDocumentsTranslations } from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreLegalDocuments/OfferSnapshotLearnMoreLegalDocuments.en.i18n";
import {
  LinkContainer,
  StyledContainer,
} from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreLegalDocuments/OfferSnapshotLearnMoreLegalDocuments.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React from "react";
import { useSelector } from "react-redux";

interface OfferSnapshotLearnMoreLegalDocumentsProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreLegalDocuments = (
  props: OfferSnapshotLearnMoreLegalDocumentsProps
) => {
  const { offerSnapshot } = props;
  const { translate } = useTranslations();
  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );
  const {
    tOfferSnapshotLearnMoreLegalDocumentsEFLDescription,
    tOfferSnapshotLearnMoreLegalDocumentsEFLTitle,
    tOfferSnapshotLearnMoreLegalDocumentsTOSDescription,
    tOfferSnapshotLearnMoreLegalDocumentsTOSTitle,
    tOfferSnapshotLearnMoreLegalDocumentsTitle,
    tOfferSnapshotLearnMoreLegalDocumentsYRACDescription,
    tOfferSnapshotLearnMoreLegalDocumentsYRACTitle,
  } = translate(offerSnapshotLearnMoreLegalDocumentsTranslations);

  return (
    <StyledContainer>
      <RhTypography variant="h2" fontWeight={FontWeight.Bold}>
        {tOfferSnapshotLearnMoreLegalDocumentsTitle}
      </RhTypography>

      <LinkContainer>
        <EFLLink offerId={offerSnapshot.id} locale={locale}>
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsEFLTitle}
          </RhTypography>
        </EFLLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsEFLDescription}
        </RhTypography>
      </LinkContainer>

      <LinkContainer>
        <TOSLink tosUrl={offerSnapshot.rhythmTosLink(locale)}>
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsTOSTitle}
          </RhTypography>
        </TOSLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsTOSDescription}
        </RhTypography>
      </LinkContainer>

      <LinkContainer>
        <YRACLink locale={locale}>
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsYRACTitle}
          </RhTypography>
        </YRACLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsYRACDescription}
        </RhTypography>
      </LinkContainer>
    </StyledContainer>
  );
};
