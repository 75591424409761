import { IdType, UUIDType } from "@common/types/apiTypes";
import { OptionalKeysRequired } from "@common/types/helperTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { CampaignTypeEnum } from "@portal/types/campaignTypes";
import {
  OfferPromoType,
  OfferSnapshotTags,
  OfferSnapshotType,
  PriceMethodTypes,
  RecProduct,
} from "@portal/types/offerSnapshotTypes";
import { PriceScheduleBreakdownVisualType } from "@portal/types/priceScheduleBreakdownVisualTypes";
import {
  GenerationPriceTierSnapshotType,
  PriceTierSnapshotType,
} from "@portal/types/priceTierSnapshotTypes";

export const AVERAGE_MONTHLY_USAGE = 2000; // TODO hardcoded for now
export const monthlyUsageAmounts = [500, 1000, 2000];

export class OfferSnapshot {
  private readonly descriptions: Record<SupportedPortalLanguage, string>;
  private readonly longDescriptions: Record<SupportedPortalLanguage, string>;
  private readonly rhythmTos: Record<SupportedPortalLanguage, string>;
  public active: boolean;
  public autopayPaperlessMonthlyDiscount?: string | null;
  public baseChargeAmount: string;
  public campaignId: IdType;
  public campaignSlug: string;
  public campaignType: CampaignTypeEnum;
  public descriptionEn: string;
  public descriptionEs: string;
  public dunsNumber: string;
  public endDate: string | undefined;
  public etfAmount: string;
  public generationPriceScheduleBreakdown:
    | PriceScheduleBreakdownVisualType[]
    | null;
  public generationPriceTierSnapshots: GenerationPriceTierSnapshotType[] | null;
  public grossMargin: number | null;
  public id: IdType;
  public longDescriptionEn: string;
  public longDescriptionEs: string;
  public offerLabels: string[];
  public offerPromoId: IdType | null;
  public planId: IdType;
  public price: string;
  public price1000Kwh: string;
  public price2000Kwh: string;
  public price500Kwh: string;
  public priceMethod: PriceMethodTypes;
  public pricePerMwh: number | null;
  public priceScheduleBreakdown: PriceScheduleBreakdownVisualType[] | null;
  public priceTierSnapshots: PriceTierSnapshotType[] | null;
  public priority: string | undefined;
  public promo: OfferPromoType | null;
  public recProduct: RecProduct;
  public rhythmAverageKwhRate: string;
  public rhythmKwhRate: string;
  public rhythmTosEn: string;
  public rhythmTosEs: string;
  public solarCreditKwhRate: string;
  public solarEligible: boolean;
  public solarGenerationCapped: boolean;
  public solarGenerationToConsumptionRatio: string | number | null;
  public startDate: string;
  public tags: OfferSnapshotTags | null;
  public tdspKwhRate: string;
  public tdspMonthlyCharge: string;
  public termMonths: number;
  public thermostatManagementMonthlyDiscount: string | null | undefined;
  public title: string;
  public uuid: UUIDType;
  public zuoraRatePlanId: IdType;

  constructor(offerSnapshot: OfferSnapshotType) {
    this.active = offerSnapshot.active;
    this.baseChargeAmount = offerSnapshot.baseChargeAmount;
    this.campaignId = offerSnapshot.campaignId;
    this.campaignSlug = offerSnapshot.campaignSlug;
    this.campaignType = offerSnapshot.campaignType;
    this.descriptionEn = offerSnapshot.descriptionEn;
    this.descriptionEs = offerSnapshot.descriptionEs;
    this.descriptions = {
      en: offerSnapshot.descriptionEn,
      es: offerSnapshot.descriptionEs,
    };
    this.dunsNumber = offerSnapshot.dunsNumber;
    this.endDate = offerSnapshot.endDate;
    this.etfAmount = offerSnapshot.etfAmount;
    this.grossMargin = offerSnapshot.grossMargin;
    this.generationPriceScheduleBreakdown =
      offerSnapshot.generationPriceScheduleBreakdown;
    this.generationPriceTierSnapshots =
      offerSnapshot.generationPriceTierSnapshots;
    this.id = offerSnapshot.id;
    this.longDescriptionEn = offerSnapshot.longDescriptionEn;
    this.longDescriptionEs = offerSnapshot.longDescriptionEs;
    this.longDescriptions = {
      en: offerSnapshot.longDescriptionEn,
      es: offerSnapshot.longDescriptionEs,
    };
    this.offerLabels = offerSnapshot.offerLabels ?? [];
    this.offerPromoId = offerSnapshot.offerPromoId;
    this.planId = offerSnapshot.planId;
    this.price = offerSnapshot.price;
    this.price1000Kwh = offerSnapshot.price1000Kwh;
    this.price2000Kwh = offerSnapshot.price2000Kwh;
    this.price500Kwh = offerSnapshot.price500Kwh;
    this.priceMethod = offerSnapshot.priceMethod;
    this.pricePerMwh = offerSnapshot.pricePerMwh;
    this.priceScheduleBreakdown = offerSnapshot.priceScheduleBreakdown;
    this.priceTierSnapshots = offerSnapshot.priceTierSnapshots;
    this.priority = offerSnapshot.priority;
    this.promo = offerSnapshot.promo;
    this.recProduct = offerSnapshot.recProduct ?? RecProduct.TXWind100;
    this.rhythmAverageKwhRate = offerSnapshot.rhythmAverageKwhRate;
    this.rhythmKwhRate = offerSnapshot.rhythmKwhRate;
    this.rhythmTos = {
      en: offerSnapshot.rhythmTosEn,
      es: offerSnapshot.rhythmTosEs,
    };
    this.rhythmTosEn = offerSnapshot.rhythmTosEn;
    this.rhythmTosEs = offerSnapshot.rhythmTosEs;
    this.solarCreditKwhRate = offerSnapshot.solarCreditKwhRate;
    this.solarEligible = offerSnapshot.solarEligible;
    this.solarGenerationCapped = offerSnapshot.solarGenerationCapped;
    this.solarGenerationToConsumptionRatio =
      offerSnapshot.solarGenerationToConsumptionRatio;
    this.startDate = offerSnapshot.startDate;
    this.tags = offerSnapshot.tags;
    this.tdspKwhRate = offerSnapshot.tdspKwhRate;
    this.tdspMonthlyCharge = offerSnapshot.tdspMonthlyCharge;
    this.termMonths = offerSnapshot.termMonths;
    this.thermostatManagementMonthlyDiscount =
      offerSnapshot.thermostatManagementMonthlyDiscount;
    this.title = offerSnapshot.title;
    this.uuid = offerSnapshot.uuid;
    this.zuoraRatePlanId = offerSnapshot.zuoraRatePlanId;
    this.autopayPaperlessMonthlyDiscount =
      offerSnapshot.autopayPaperlessMonthlyDiscount;
  }

  get isFeatured(): boolean {
    const priority = Number(this.priority);

    if (priority > 0 && priority <= 3) {
      return true;
    }

    return false;
  }

  get status(): string {
    if (this.active) {
      return "Active";
    }
    return "Expired";
  }

  get isTimeOfUse(): boolean {
    return this.priceTierSnapshots !== null;
  }

  get isTimeOfUseGeneration(): boolean {
    return (
      this.generationPriceTierSnapshots !== null &&
      this.generationPriceTierSnapshots.length > 0
    );
  }

  get isMonthToMonth(): boolean {
    return Boolean(this.termMonths && this.termMonths === 1);
  }

  get isNoFrillsOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "No-frills".toLowerCase()
    );
  }

  get isSimpliSafeOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "SimpliSafe".toLowerCase()
    );
  }

  get isSimpliSafeOutdoorOffer(): boolean {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "SimpliSafe-Outdoor".toLowerCase()
    );
  }

  get isTwoFreeMonthsOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "2 Months Free".toLowerCase()
    );
  }

  get isIncentiveOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Incentive".toLowerCase()
    );
  }

  get isAutoPayPaperlessDiscountOffer() {
    return (
      Boolean(this.autopayPaperlessMonthlyDiscount) &&
      Number(this.autopayPaperlessMonthlyDiscount) > 0
    );
  }

  get isSolarBuybackPriceDifferential() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "SBB Price Differential".toLowerCase()
    );
  }

  get isEvOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "EV".toLowerCase()
    );
  }

  get isShowEnergyRateOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Show energy rate".toLowerCase()
    );
  }

  get isSolarOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Solar".toLowerCase()
    );
  }

  get isWindOffer() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Wind".toLowerCase()
    );
  }

  get isMostPopular() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Most Popular".toLowerCase()
    );
  }

  get isThermostatOffer() {
    return typeof this.thermostatManagementMonthlyDiscount === "string";
  }

  get isThermostatIncluded() {
    return this.offerLabels.some(
      (label) => label.toLowerCase() === "Thermostat Included".toLowerCase()
    );
  }

  get totalRate(): number {
    return parseFloat(this.rhythmKwhRate) + parseFloat(this.tdspKwhRate);
  }

  get totalCharge(): number {
    return (
      parseFloat(this.tdspMonthlyCharge) +
      parseFloat(this.baseChargeAmount) -
      parseFloat(this.autopayPaperlessMonthlyDiscount ?? "0")
    );
  }

  get formattedCurrencyKwhRate(): string {
    return this.rhythmKwhRate
      ? formatCurrency(Number(this.rhythmKwhRate), 3)
      : "-";
  }

  get formattedCurrencyBaseCharge(): string {
    return this.baseChargeAmount
      ? formatCurrency(Number(this.baseChargeAmount))
      : "-";
  }

  get hasBaseChargeAmount(): boolean {
    return Boolean(
      this.baseChargeAmount && parseFloat(this.baseChargeAmount) > 0
    );
  }

  description(locale: SupportedPortalLanguage): string {
    return this.descriptions[locale];
  }

  longDescription(locale: SupportedPortalLanguage): string {
    return this.longDescriptions[locale];
  }

  rhythmTosLink(locale: SupportedPortalLanguage): string {
    return this.rhythmTos[locale];
  }

  hasPromo() {
    return Boolean(this.promo);
  }

  get tagCallout() {
    if (!this.tags) {
      return null;
    }

    return {
      backgroundColor: this.tags.calloutColor,
      title: {
        en: this.tags.calloutEn,
        es: this.tags.calloutEs,
      },
    };
  }

  hasCallout() {
    return Boolean(this.tagCallout?.title?.en);
  }

  calloutText(locale: SupportedPortalLanguage): string | null | undefined {
    return this.tagCallout?.title[locale];
  }

  calloutBackgroundColor() {
    return this.tagCallout?.backgroundColor as string;
  }

  averageMonthlyPrice = (
    monthlyUsage: number = AVERAGE_MONTHLY_USAGE
  ): number => {
    return this.totalRate * monthlyUsage + this.totalCharge;
  };

  monthlyEstimate2000Kwh() {
    return Number(this.price2000Kwh) * 2000;
  }

  monthlyEstimate1000Kwh() {
    return Number(this.price1000Kwh) * 1000;
  }

  monthlyEstimate500Kwh() {
    return Number(this.price500Kwh) * 500;
  }
  displayPrice = (): number =>
    this.solarEligible
      ? Number(this.rhythmKwhRate) * 100
      : Number(this.price2000Kwh) * 100;

  termAndRate = () => {
    return `${this.termMonths} months, ${
      this.isMonthToMonth ? "Variable" : "Fixed"
    } Rate`;
  };

  toType(): OfferSnapshotType {
    const offerSnapshotType: OptionalKeysRequired<OfferSnapshotType> = {
      active: this.active,
      autopayPaperlessMonthlyDiscount: this
        .autopayPaperlessMonthlyDiscount as string,
      baseChargeAmount: this.baseChargeAmount,
      campaignId: this.campaignId,
      campaignSlug: this.campaignSlug,
      campaignType: this.campaignType,
      descriptionEn: this.descriptionEn,
      descriptionEs: this.descriptionEs,
      dunsNumber: this.dunsNumber,
      endDate: this.endDate as string,
      etfAmount: this.etfAmount,
      generationPriceScheduleBreakdown: this.generationPriceScheduleBreakdown,
      generationPriceTierSnapshots: this.generationPriceTierSnapshots,
      grossMargin: this.grossMargin,
      id: this.id,
      longDescriptionEn: this.longDescriptionEn,
      longDescriptionEs: this.longDescriptionEs,
      offerLabels: this.offerLabels,
      offerPromoId: this.offerPromoId,
      planId: this.planId,
      price: this.price,
      price1000Kwh: this.price1000Kwh,
      price2000Kwh: this.price2000Kwh,
      price500Kwh: this.price500Kwh,
      priceMethod: this.priceMethod,
      pricePerMwh: this.pricePerMwh,
      priceScheduleBreakdown: this.priceScheduleBreakdown,
      priceTierSnapshots: this.priceTierSnapshots,
      priority: this.priority as string,
      promo: this.promo,
      recProduct: this.recProduct,
      rhythmAverageKwhRate: this.rhythmAverageKwhRate,
      rhythmKwhRate: this.rhythmKwhRate,
      rhythmTosEn: this.rhythmTosEn,
      rhythmTosEs: this.rhythmTosEs,
      solarCreditKwhRate: this.solarCreditKwhRate,
      solarEligible: this.solarEligible,
      solarGenerationCapped: this.solarGenerationCapped,
      solarGenerationToConsumptionRatio: this.solarGenerationToConsumptionRatio,
      startDate: this.startDate,
      tags: this.tags,
      tdspKwhRate: this.tdspKwhRate,
      tdspMonthlyCharge: this.tdspMonthlyCharge,
      termMonths: this.termMonths,
      thermostatManagementMonthlyDiscount:
        this.thermostatManagementMonthlyDiscount,
      title: this.title,
      uuid: this.uuid,
      zuoraRatePlanId: this.zuoraRatePlanId,
    };

    return offerSnapshotType as OfferSnapshotType;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<
  OfferSnapshotType,
  OfferSnapshot
> = undefined;
