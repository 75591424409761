import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { ModalPlacement } from "@design-system/components/RhModal/RhModal.styled";
import { ReactComponent as ChevronUp } from "@design-system/icons/ChevronUp.svg";
import { CartDialog } from "@portal/components/CartDialog/CartDialog";
import { CartMobileContainer } from "@portal/components/CartMobile/CartMobile.style";
import { CartToggle } from "@portal/components/CartToggle/CartToggle";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { useSelectedProductTiers } from "@portal/hooks/useSelectedProductTiers";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export const CartMobile = () => {
  const { offerSnapshotUuid } = useSelector(selectSignUpState);
  const selectedProductTiers = useSelectedProductTiers();
  const [cartOpen, setCartOpen] = useState<boolean>(false);

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(offerSnapshotUuid),
    },
  });

  const toggleCart = () => setCartOpen((open) => !open);
  const closeCart = () => {
    // See CartToggle.tsx for more info on this implementation
    document.body.classList.remove(CSSClasses.OverflowHidden);

    setCartOpen(false);
  };

  const offerSnapshot = offerSnapshotQuery.data;

  return offerSnapshot ? (
    <>
      <CartMobileContainer cartOpen={cartOpen}>
        <CartToggle
          offerSnapshot={offerSnapshot}
          handleClick={toggleCart}
          cartOpen={cartOpen}
          itemCount={selectedProductTiers.length + 1}
          chevron={<ChevronUp />}
          isMobileCart
        />
      </CartMobileContainer>
      {cartOpen && (
        <CartDialog
          offerSnapshot={offerSnapshot}
          dialogPlacement={ModalPlacement.Bottom}
          closeCart={closeCart}
          showPortalFooter
          hideCartCloseButton
          isMobileCart
          selectedProductTiers={selectedProductTiers}
        />
      )}
    </>
  ) : null;
};
