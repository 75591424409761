import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

const getCookieNameWithEnv = <T extends object>(
  cookieName: keyof T,
  defaultCookiesWithEnvValues: T
) => {
  const finalCookieName =
    getEnv() !== EnvTypes.production &&
    Object.keys(defaultCookiesWithEnvValues).includes(String(cookieName))
      ? `${String(cookieName)}-${getEnv()}`
      : cookieName;

  return finalCookieName;
};

// Only used for any cookie use in th logged in experience
export const useAppCookies = <T extends object>(
  defaultCookiesValues: T,
  defaultCookiesWithEnvValues: T = {} as T
) => {
  type KeyOfT = keyof T;

  const [documentCookies, documentSetCookie, documentRemoveCookie] =
    useCookies();

  const [cookies, setCookies] = useState<T>({} as T);

  useEffect(() => {
    // Get only the cookies from cookies passed, ignore any others that happen to be in document.cookie
    const newCookies = Object.entries(defaultCookiesValues).reduce<T>(
      (acc, current) => {
        const [cookieName, defaultCookieValue] = current;

        const cookieNameWithEnv = getCookieNameWithEnv(
          cookieName as keyof T,
          defaultCookiesWithEnvValues
        );

        const cookieValue =
          documentCookies[cookieNameWithEnv as keyof typeof documentCookies];

        return {
          ...acc,
          [cookieName]: cookieValue ?? defaultCookieValue,
        };
      },
      { ...defaultCookiesValues }
    );

    setCookies(newCookies);
  }, [defaultCookiesValues, defaultCookiesWithEnvValues, documentCookies]);

  type SetCookieType = typeof documentSetCookie;

  const setCookie = useCallback(
    (
      cookieName: KeyOfT,
      cookieValue: T[KeyOfT],
      options?: Parameters<SetCookieType>[2]
    ) => {
      const cookieDomain = window.location.hostname.replace(/^app/, "");
      const finalCookieName = getCookieNameWithEnv(
        cookieName,
        defaultCookiesWithEnvValues
      );

      documentSetCookie(String(finalCookieName), cookieValue, {
        ...options,
        domain: cookieDomain,
        path: "/",
        sameSite: "strict", // https://web.dev/articles/samesite-cookies-explained
        secure: true,
      });
    },
    [defaultCookiesWithEnvValues, documentSetCookie]
  );

  type RemoveCookieType = typeof documentRemoveCookie;

  const removeCookie = useCallback(
    (cookieName: keyof T, options?: Parameters<RemoveCookieType>[1]) => {
      const finalCookieName = getCookieNameWithEnv(
        cookieName,
        defaultCookiesWithEnvValues
      );

      documentRemoveCookie(String(finalCookieName), options);
    },
    [defaultCookiesWithEnvValues, documentRemoveCookie]
  );

  return useMemo(
    () => ({
      cookies,
      removeCookie,
      setCookie,
    }),
    [removeCookie, cookies, setCookie]
  );
};
