import { usePortalCookies } from "@portal/hooks/usePortalCookies";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
  track,
} from "@portal/services/segment.service";
import { useEffect, useState } from "react";

export const useTrackFullStoryUserId = (): undefined => {
  const [tracked, setTracked] = useState<boolean>(false);
  const {
    cookies: { _fs_uid: fullStoryUserIdCookieValue },
  } = usePortalCookies();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const fsInitialized = window._fs_initialized;

    if (fsInitialized) {
      if (!tracked && fullStoryUserIdCookieValue) {
        track({
          action: ActionType.userIdentified,
          category: CategoryType.FullStory,
          event: EventType.identify,
          label: LabelType.FullStoryUserIdentified,
          value: fullStoryUserIdCookieValue,
        });

        setTracked(true);
      }
    }
  }, [fullStoryUserIdCookieValue, tracked]);
};
