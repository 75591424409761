import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import {
  fontBold,
  fontBoldItalic,
  fontLight,
  fontLightItalic,
  fontMedium,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
} from "@design-system/theme/avertaFont";
import { fontFamily } from "@design-system/theme/typography";
import { createGlobalStyle } from "styled-components";

export enum CSSClasses {
  OverflowHidden = "overflow-hidden",
}

export const CssReset = createGlobalStyle`
  @font-face {
    font-display: ${fontLight.fontDisplay};
    font-family: ${fontLight.fontFamily};
    font-style: ${fontLight.fontStyle};
    font-weight: ${fontLight.fontWeight};
    src: ${fontLight.src};
    unicode-range: ${fontLight.unicodeRange};
  }

  @font-face {
    font-display: ${fontLightItalic.fontDisplay};
    font-family: ${fontLightItalic.fontFamily};
    font-style: ${fontLightItalic.fontStyle};
    font-weight: ${fontLightItalic.fontWeight};
    src: ${fontLightItalic.src};
    unicode-range: ${fontLightItalic.unicodeRange};
  }
  @font-face {
    font-display: ${fontRegular.fontDisplay};
    font-family: ${fontRegular.fontFamily};
    font-style: ${fontRegular.fontStyle};
    font-weight: ${fontRegular.fontWeight};
    src: ${fontRegular.src};
    unicode-range: ${fontRegular.unicodeRange};
  }
  @font-face {
    font-display: ${fontRegularItalic.fontDisplay};
    font-family: ${fontRegularItalic.fontFamily};
    font-style: ${fontRegularItalic.fontStyle};
    font-weight: ${fontRegularItalic.fontWeight};
    src: ${fontRegularItalic.src};
    unicode-range: ${fontRegularItalic.unicodeRange};
  }
  @font-face {
    font-display: ${fontMedium.fontDisplay};
    font-family: ${fontMedium.fontFamily};
    font-style: ${fontMedium.fontStyle};
    font-weight: ${fontMedium.fontWeight};
    src: ${fontMedium.src};
    unicode-range: ${fontMedium.unicodeRange};
  }
  @font-face {
    font-display: ${fontMediumItalic.fontDisplay};
    font-family: ${fontMediumItalic.fontFamily};
    font-style: ${fontMediumItalic.fontStyle};
    font-weight: ${fontMediumItalic.fontWeight};
    src: ${fontMediumItalic.src};
    unicode-range: ${fontMediumItalic.unicodeRange};
  }
  @font-face {
    font-display: ${fontBold.fontDisplay};
    font-family: ${fontBold.fontFamily};
    font-style: ${fontBold.fontStyle};
    font-weight: ${fontBold.fontWeight};
    src: ${fontBold.src};
    unicode-range: ${fontBold.unicodeRange};
  }
  @font-face {
    font-display: ${fontBoldItalic.fontDisplay};
    font-family: ${fontBoldItalic.fontFamily};
    font-style: ${fontBoldItalic.fontStyle};
    font-weight: ${fontBoldItalic.fontWeight};
    src: ${fontBoldItalic.src};
    unicode-range: ${fontBoldItalic.unicodeRange};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ul,
  ol {
    list-style: none;
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    font-weight: normal;
    line-height: normal;
  }

  img {
    display: block;
    max-width: 100%;
  }

  article > * + * {
    margin-top: 1em;
  }

  /* Fix for blurry texts on Chrome and Firefox */
  @media (min-width: 1920px) {
    body {
      font-size: 100%;
    }
  }

  body {
    color: ${CSSVariables.COLOR_GREY_900};
    font-family: ${fontFamily};
    font-size: 12px;
    font-variant-numeric: tabular-nums;
    line-height: 1.3;
    overflow-x: hidden;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    
    &.${CSSClasses.OverflowHidden} {
      overflow: hidden;
    }
    
  }
  a {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    text-decoration: none;

    &:not(.MuiButton-root):hover {
      text-decoration: underline;
    }

    &:not(.MuiButton-root):active {
      color: ${CSSVariables.COLOR_PRIMARY_DARK};
      text-decoration: underline;
    }
  }
  html {
          scroll-behavior: smooth;
  }
  ul: {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;
