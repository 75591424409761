import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import { Skeleton } from "@portal/styled/skeleton.styled";
import styled from "styled-components";

export const StyledBillBreakdownInvoiceCard = styled(PortalCard)`
  display: grid;
  gap: ${rhSpacingPx(2)};
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto auto;
  padding: ${rhSpacingPx(3)};
  text-align: start;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const StyledDownloadLink = styled.button`
  appearance: none;
  background: none;
  border: 0;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  font-family: inherit;
  font-size: inherit;
  padding: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const StyledBillBreakdownInvoiceCardTitle = styled(RhTypography).attrs({
  fontWeight: "Bold",
})`
  color: ${CSSVariables.COLOR_GREY_700};
`;

export const StyledBillBreakdownInvoiceCardDivider = styled(RhDivider)`
  &.MuiDivider-root {
    background-color: ${CSSVariables.COLOR_GREY_100};
    grid-column: span 5;
  }
`;

export const StyledBillBreakdownInvoiceCardSkeleton = styled(Skeleton)`
  grid-column: span 4;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    grid-column: span 5;
  }
`;

export const StyledInvoiceLink = styled(RhTypography)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
