import { MONTH_DAY_YEAR_SLASH } from "@common/constants/date.constant";
import { formatMaskNumber } from "@common/forms/formatters";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { Premise } from "@common/models/Premise.model";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import {
  RhSelect,
  RhSelectLabel,
} from "@design-system/components/RhSelect/RhSelect.styled";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as CircularMoneyIcon } from "@design-system/icons/CircularMoneyIcon.svg";
import { myAccountAutoPayModalTranslations } from "@portal/components/MyAccountAutoPayModal/MyAccountAutoPayModal.en.i18n";
import {
  AutoPayForm,
  AutoPayFormButtonContainer,
  AutoPayModalContainer,
  AutoPayToggle,
  BalancedText,
  LegalText,
  LoadingIndicator,
} from "@portal/components/MyAccountAutoPayModal/MyAccountAutoPayModal.styled";
import { PortalCard } from "@portal/components/PortalCard/PortalCard.styled";
import { usePremiseAutoPayUpdateMutation } from "@portal/hooks/mutations/usePremiseAutoPayUpdate.mutation";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { useTranslations } from "@portal/hooks/useTranslations";
import { AccountSummary } from "@portal/models/AccountSummary.model";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { ActionType, MyAccountEvents } from "@portal/services/segment.service";
import { billingDetailsRefetch } from "@portal/slices/billingDetailsSlice";
import { premiseRefetch } from "@portal/slices/premiseSlice";
import dayjs from "dayjs";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

interface MyAccountAutoPayModalProps {
  accountSummary: AccountSummary;
  handleClose: () => void;
  offerSnapshot: OfferSnapshot;
  paymentMethods: PaymentMethod[];
  premise: Premise;
}

interface AutoPayFormValues {
  autopayEnabled: boolean;
  paymentMethod?: string;
}

const AutoPayTitle = ({
  offerSnapshot,
  premise,
}: {
  offerSnapshot: OfferSnapshot;
  premise: Premise;
}) => {
  const { translate, translateJsx } = useTranslations();

  const {
    tMyAccountAutoPayModalTitle,
    tMyAccountAutoPayModalIfDisableWarningSubTitle,
  } = translate(myAccountAutoPayModalTranslations);

  const {
    tMyAccountAutoPayModalWhenDisabledWarningTitle,
    tMyAccountAutoPayModalIfDisableWarningTitle,
  } = translateJsx({
    tMyAccountAutoPayModalIfDisableWarningTitle: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
      ),
    },
    tMyAccountAutoPayModalWhenDisabledWarningTitle: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
      ),
    },
  });

  if (offerSnapshot.isAutoPayPaperlessDiscountOffer && premise.autopay) {
    return (
      <>
        <BalancedText
          variant="subtitle2"
          color="error"
          fontWeight={FontWeight.Bold}
        >
          {tMyAccountAutoPayModalIfDisableWarningTitle}
        </BalancedText>
        <BalancedText variant="body1" color="textSecondary">
          {tMyAccountAutoPayModalIfDisableWarningSubTitle}
        </BalancedText>
      </>
    );
  }

  if (offerSnapshot.isAutoPayPaperlessDiscountOffer && !premise.autopay) {
    return (
      <BalancedText
        variant="subtitle2"
        fontWeight={FontWeight.Bold}
        color="error"
      >
        {tMyAccountAutoPayModalWhenDisabledWarningTitle}
      </BalancedText>
    );
  }

  return (
    <BalancedText variant="subtitle2" fontWeight={FontWeight.Bold}>
      {tMyAccountAutoPayModalTitle}
    </BalancedText>
  );
};

export const MyAccountAutoPayModal = (props: MyAccountAutoPayModalProps) => {
  const {
    premise,
    handleClose,
    paymentMethods,
    accountSummary,
    offerSnapshot,
  } = props;
  const { translate, translateJsx } = useTranslations();
  const flash = useRhFlash();
  const dispatch = useDispatch();
  const track = useTrackMyAccountEvents();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<AutoPayFormValues>({
    defaultValues: {
      autopayEnabled: premise.autopay,
      paymentMethod: premise.billingPaymentMethodId ?? "",
    },
  });

  const [autopayEnabled] = watch(["autopayEnabled"]);

  const premiseAutoPayUpdateMutation = usePremiseAutoPayUpdateMutation();

  const {
    tMyAccountAutoPayModalAutoPayLabel,
    tMyAccountAutoPayModalUpdateFailure,
    tMyAccountAutoPayModalUpdateSuccess,
    tMyAccountAutoPayModalCancelButtonText,
    tMyAccountAutoPayModalConfirmButtonText,
    tMyAccountAutoPayModalModalCloseLabel,
    tMyAccountAutoPayModalModalLabel,
    tMyAccountAutoPayModalNextDueDate,
    tMyAccountAutoPayModalPaymentMethodLabel,
  } = translate(myAccountAutoPayModalTranslations);

  const {
    tMyAccountAutoPayModalYouWillBeCharged,
    tMyAccountAutoPayModalYourNextCharge,
    tMyAccountAutoPayModalPastDueCharged,
  } = translateJsx({
    tMyAccountAutoPayModalPastDueCharged: {
      pastDueAmount: formatCurrency(accountSummary.pastDueBalance),
      todaysDate: dayjs().format(MONTH_DAY_YEAR_SLASH),
    },
    tMyAccountAutoPayModalYouWillBeCharged: {
      dueDate: dayjs(accountSummary.nextDueDate).format(MONTH_DAY_YEAR_SLASH),
    },
    tMyAccountAutoPayModalYourNextCharge: {
      dueDate: dayjs(accountSummary.nextDueDate).format(MONTH_DAY_YEAR_SLASH),
    },
  });

  const onSubmit = handleSubmit((values: AutoPayFormValues) => {
    return premiseAutoPayUpdateMutation.mutate(
      {
        enabled: values.autopayEnabled,
        paymentMethodId: values.paymentMethod,
        premiseId: premise.id,
      },
      {
        onError: () => {
          flash.error(tMyAccountAutoPayModalUpdateFailure);
          handleClose();
        },
        onSuccess: () => {
          dispatch(premiseRefetch());
          dispatch(billingDetailsRefetch());
          flash.success(tMyAccountAutoPayModalUpdateSuccess);

          track({
            action: ActionType.click,
            event: MyAccountEvents.autoPayToggle,
            label: "Toggled Auto pay",
            value: values.autopayEnabled,
          });

          handleClose();
        },
      }
    );
  });

  const autoPaySubtext = () => {
    if (!accountSummary.nextDueDate) {
      return tMyAccountAutoPayModalNextDueDate;
    }

    if (Number(accountSummary.pastDueBalance) > 0) {
      return tMyAccountAutoPayModalPastDueCharged;
    }

    if (premise.autopay) {
      return tMyAccountAutoPayModalYourNextCharge;
    }
    return tMyAccountAutoPayModalYouWillBeCharged;
  };

  const isFormSubmitting =
    premiseAutoPayUpdateMutation.isPending || isSubmitting;

  return (
    <RhModal
      ariaCloseLabel={tMyAccountAutoPayModalModalCloseLabel}
      ariaLabel={tMyAccountAutoPayModalModalLabel}
      handleClose={handleClose}
      size={ModalWidths.sm}
    >
      <AutoPayModalContainer>
        <CircularMoneyIcon aria-hidden width={56} height={56} />
        <AutoPayTitle premise={premise} offerSnapshot={offerSnapshot} />

        <AutoPayForm onSubmit={onSubmit}>
          <PortalCard $noShadow>
            <AutoPayToggle
              dataTrackingClick={{
                event: "Customer toggling autopay",
                value: String(autopayEnabled),
              }}
              inputProps={register("autopayEnabled")}
            >
              {tMyAccountAutoPayModalAutoPayLabel}
            </AutoPayToggle>

            <LegalText variant="body2" color="textSecondary">
              {autoPaySubtext()}
            </LegalText>
          </PortalCard>

          <RhSelectLabel>
            {tMyAccountAutoPayModalPaymentMethodLabel}
            <RhSelect {...register("paymentMethod")}>
              {paymentMethods.map((paymentMethod) => {
                return (
                  <option value={paymentMethod.id} key={paymentMethod.id}>
                    {`${paymentMethod.companyName} ${formatMaskNumber(
                      paymentMethod.maskedNumber
                    )}`}
                  </option>
                );
              })}
            </RhSelect>
          </RhSelectLabel>

          <AutoPayFormButtonContainer>
            <RhButton
              data-tracking-click={{
                event: "customer closed auto pay modal without switching",
              }}
              type="button"
              onClick={handleClose}
              variant="outlined"
              fullWidth
            >
              {tMyAccountAutoPayModalCancelButtonText}
            </RhButton>
            <RhButton
              data-tracking-click={{
                event: "customer switching to auto pay",
              }}
              type="submit"
              fullWidth
              disabled={isFormSubmitting}
            >
              {isFormSubmitting ? (
                <LoadingIndicator>
                  <RhCircularProgress
                    marginBottom={0}
                    marginTop={0}
                    size={24}
                  />
                </LoadingIndicator>
              ) : null}
              {tMyAccountAutoPayModalConfirmButtonText}
            </RhButton>
          </AutoPayFormButtonContainer>
        </AutoPayForm>
      </AutoPayModalContainer>
    </RhModal>
  );
};
