import { ajax } from "@common/services/ajax";
import { UUIDType } from "@common/types/apiTypes";
import { ProspectType } from "@common/types/prospectTypes";
import {
  GetReferralCodeResponse,
  ProspectCreateRequest,
  ProspectUpdateRequestType,
} from "@enroll-utils/types/prospectTypes";

const PROSPECT_BASE_URL = "/api/prospects";

export const prospectApi = {
  create: (body: ProspectCreateRequest): Promise<ProspectType> => {
    return ajax.post(`${PROSPECT_BASE_URL}/`, body);
  },
  getReferralCode: (
    prospectUuid: UUIDType,
    code: string
  ): Promise<GetReferralCodeResponse> => {
    return ajax.get(
      `${PROSPECT_BASE_URL}/${prospectUuid}/referral-code/${code}/`
    );
  },
  retrieve: (prospectUuid: UUIDType): Promise<ProspectType> => {
    return ajax.get<ProspectType>(`${PROSPECT_BASE_URL}/${prospectUuid}`);
  },
  update: ({
    uuid,
    data,
  }: {
    data: ProspectUpdateRequestType;
    uuid: UUIDType;
  }) => ajax.patch<null>(`${PROSPECT_BASE_URL}/${uuid}`, data),
};
