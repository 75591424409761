import { ReactComponent as LeafIcon } from "@design-system/icons/LeafIcon.svg";
import { ReactComponent as SolarEnergyIcon } from "@design-system/icons/SolarEnergyIcon.svg";
import { ReactComponent as WindTurbineIcon } from "@design-system/icons/WindTurbineIcon.svg";
import { TranslationKey } from "@portal/hooks/useRhIntl";
import { RecProduct } from "@portal/types/offerSnapshotTypes";
import { FunctionComponent } from "react";

interface OfferCardDataFromRecProduct {
  Icon: FunctionComponent;
  id: string;
  textKey: TranslationKey;
}

export const getOfferCardDataFromRecProduct = (
  recProduct: RecProduct
): OfferCardDataFromRecProduct => {
  switch (recProduct) {
    case RecProduct.Solar100: {
      return {
        Icon: SolarEnergyIcon,
        id: "solar100",
        textKey: "tOfferFeaturesSolar",
      };
    }
    case RecProduct.Chapman100: {
      return {
        Icon: WindTurbineIcon,
        id: "chapman100",
        textKey: "tOfferFeaturesChapman",
      };
    }
    case RecProduct.TXWind100: {
      return {
        Icon: WindTurbineIcon,
        id: "txWind100",
        textKey: "tOfferFeaturesWind",
      };
    }
    default: {
      return {
        Icon: LeafIcon,
        id: "anyRec",
        textKey: "tOfferFeaturesAnyRec",
      };
    }
  }
};
