import { useRhIntl } from "@portal/hooks/useRhIntl";
import enBaseJson from "@portal/translations/en-base.json";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

type EnBaseJsonTypes = typeof enBaseJson;

export type TranslationKey = keyof EnBaseJsonTypes;

const getTranslate = (tFn: ReturnType<typeof useRhIntl>["t"]) => {
  const translate = <T extends Partial<Record<TranslationKey, string>>>(
    keysToTranslateWithoutParams: T
  ): Record<keyof T, string> => {
    return Object.keys(keysToTranslateWithoutParams).reduce(
      (acc, currentTranslationKey) => {
        return {
          ...acc,
          [currentTranslationKey]: tFn(currentTranslationKey as TranslationKey),
        };
      },
      {} as Record<keyof T, string>
    );
  };

  return translate;
};

const getTranslateJsx = (tJsxFn: ReturnType<typeof useRhIntl>["tJsx"]) => {
  const translate = <T extends Partial<Record<TranslationKey, string>>>(
    keysToTranslateWithParams: Partial<
      Record<keyof T, Record<string, unknown>>
    > = {}
  ): Record<keyof T, JSX.Element> => {
    return Object.entries(keysToTranslateWithParams).reduce(
      (acc, current) => {
        const [translationKey, translationValues] = current;

        type TArgs = Parameters<ReturnType<typeof useIntl>["formatMessage"]>;
        type TValues = TArgs[1];

        return {
          ...acc,
          [translationKey]: (
            <>
              {tJsxFn(
                translationKey as TranslationKey,
                translationValues as TValues
              )}
            </>
          ),
        };
      },
      {} as Record<keyof T, JSX.Element>
    );
  };

  return translate;
};

export const useTranslations = () => {
  const { t, tJsx } = useRhIntl();

  const [translate, setTranslate] = useState({
    translate: getTranslate(t),
    translateJsx: getTranslateJsx(tJsx),
  });

  useEffect(() => {
    setTranslate({
      translate: getTranslate(t),
      translateJsx: getTranslateJsx(tJsx),
    });
  }, [t]);

  return translate;
};
