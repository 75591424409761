import { formatCurrency, formatToCents } from "@common/utils/dataFormatters";
import { getBrandTheme } from "@design-system/brandTheme/getBrandTheme";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { fontFamily } from "@design-system/theme/typography";
import { GroupedCompetitorPlans } from "@portal/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.types";
import { RHYTHM } from "@portal/constants/misc.constant";
import { DEFAULT_AVERAGE_MONTHLY_USAGE } from "@portal/constants/offer.constant";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import { CompetitorNames } from "@portal/types/competitorTypes";
import { ChartOptions } from "chart.js";

const theme = getBrandTheme();

interface BuildChartOptionsProps {
  offerSnapshot: OfferSnapshot;
  tooltipText: string[];
}

export const buildChartOptions = (props: BuildChartOptionsProps) => {
  const { offerSnapshot, tooltipText } = props;

  return {
    interaction: {
      intersect: false,
      mode: "x",
    },
    layout: {
      padding: {
        bottom: 40,
      },
    },

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: theme.colors.white,
        bodyAlign: "center",
        bodyColor: theme.colors.black,
        bodyFont: {
          family: fontFamily,
          size: 16,
          weight: FontWeight.Regular,
        },
        bodySpacing: 0,
        borderColor: theme.colors.primary.main,
        borderWidth: 2,
        callbacks: {
          label: (tooltipItem) => {
            const labelItems = [];
            const raw = tooltipItem.raw as unknown as GroupedCompetitorPlans;

            if (raw.competitorName !== (RHYTHM as CompetitorNames)) {
              const savings = formatCurrency(
                ((raw.averagePrice - offerSnapshot.displayPrice()) / 100) *
                  DEFAULT_AVERAGE_MONTHLY_USAGE *
                  12
              );

              labelItems.push(savings);
            }
            return labelItems;
          },
          title: (tooltipItems) => {
            const titleItems = [];

            if (tooltipItems.length > 0 && tooltipItems[0].label !== RHYTHM) {
              titleItems.push(...tooltipText);
            }

            return titleItems;
          },
        },
        displayColors: false,
        filter(tooltipItem, data) {
          return tooltipItem.label !== RHYTHM;
        },
        padding: 12,
        titleAlign: "center",
        titleColor: theme.colors.black,
        titleFont: {
          family: fontFamily,
          size: 14,
          weight: FontWeight.Bold,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          color: "transparent",
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        grace: 5,
        ticks: {
          callback: (value, index, ticks) => {
            return formatToCents(value, true, 0);
          },
        },
      },
    },
  } satisfies ChartOptions;
};
