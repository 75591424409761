import { Order } from "@common/models/Order.model";
import { Premise } from "@common/models/Premise.model";
import { SupportedPortalLanguage } from "@common/types/languageTypes";
import {
  formatDateRange,
  formatDollarsToCents,
} from "@common/utils/dataFormatters";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CalendarIcon } from "@design-system/icons/CalendarIcon.svg";
import { ReactComponent as Clock } from "@design-system/icons/Clock.svg";
import { ReactComponent as FlashyDollar } from "@design-system/icons/FlashyDollar.svg";
import { ReactComponent as LockIcon } from "@design-system/icons/LockIcon.svg";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { ReactComponent as TagIcon } from "@design-system/icons/TagIcon.svg";
import {
  TOSLink,
  WelcomePacketLink,
} from "@portal/components/LegalDocuments/LegalDocumentLinks";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { yourCurrentPlanSectionTranslations } from "@portal/components/YourCurrentPlanSection/YourCurrentPlanSection.en.i18n";
import {
  StyledBody,
  StyledBreakdown,
  StyledCard,
  StyledFeature,
  StyledFeatures,
  StyledFeaturesColumn,
  StyledPlanTitle,
} from "@portal/components/YourCurrentPlanSection/YourCurrentPlanSection.styled";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { useTrackMyAccountEvents } from "@portal/hooks/useTrackMyAccountEvents";
import { useTranslations } from "@portal/hooks/useTranslations";
import { selectCustomerLanguagePreference } from "@portal/selectors/customerPreferencesSelectors";
import { ActionType, MyAccountEvents } from "@portal/services/segment.service";
import { PortalStoreState } from "@portal/types/portalSlicesTypes";
import React from "react";
import { useSelector } from "react-redux";

type YourCurrentPlanSectionProps = {
  order: Order;
  premise: Premise;
};
export const YourCurrentPlanSection = ({
  order,
  premise,
}: YourCurrentPlanSectionProps) => {
  const { translate } = useTranslations();
  const {
    tYourCurrentPlanSectionViewPriceBreakdown,
    tYourCurrentPlanSectionEnergyCharge,
    tYourCurrentPlanSectionMonthToMonth,
    tYourCurrentPlanSectionYourPlanDetails,
    tYourCurrentPlanSectionFixedRate,
    tYourCurrentPlanSectionTimeDependentFixedRate,
    tYourCurrentPlanSectionVariableRate,
    tYourCurrentPlanSectionYourContractDocuments,
    tYourCurrentTermsOfService,
  } = translate(yourCurrentPlanSectionTranslations);

  const locale = useSelector<PortalStoreState, SupportedPortalLanguage>(
    selectCustomerLanguagePreference
  );

  const { data: offerSnapshot } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: order.offerSnapshotUuid,
  });

  const { addModal } = useModals();
  const track = useTrackMyAccountEvents();

  if (!offerSnapshot) {
    return null;
  }

  const { isMonthToMonth } = offerSnapshot;

  const getRateInfo = () => {
    if (order.isMonthToMonth) {
      return (
        <>
          <TagIcon />
          <RhTypography>{tYourCurrentPlanSectionVariableRate}</RhTypography>
        </>
      );
    }

    if (order.isTimeOfUse) {
      return (
        <>
          <Clock />
          <RhTypography>
            {tYourCurrentPlanSectionTimeDependentFixedRate}
          </RhTypography>
        </>
      );
    }

    return (
      <>
        <LockIcon />
        <RhTypography>{tYourCurrentPlanSectionFixedRate}</RhTypography>
      </>
    );
  };

  return (
    <StyledCard title={tYourCurrentPlanSectionYourPlanDetails}>
      <StyledBody>
        <StyledPlanTitle variant="h1" fontWeight="Bold">
          {order.title}
        </StyledPlanTitle>
        <StyledFeatures>
          <StyledFeaturesColumn>
            <StyledFeature>
              <CalendarIcon />
              <RhTypography>
                {isMonthToMonth
                  ? tYourCurrentPlanSectionMonthToMonth
                  : formatDateRange(order.startDate, order.endDate)}
              </RhTypography>
            </StyledFeature>
            <StyledFeature>
              {order.isTimeOfUse ? (
                <StyledBreakdown
                  onClick={() => {
                    addModal({
                      energyBreakdown: {
                        offerSnapshotUuid: offerSnapshot.uuid,
                      },
                    });

                    track({
                      action: ActionType.click,
                      event: MyAccountEvents.viewPriceBreakdown,
                      label: "Home page - Opened view price breakdown modal",
                    });
                  }}
                >
                  <FlashyDollar />
                  <RhTypography color="primary">
                    {tYourCurrentPlanSectionViewPriceBreakdown}
                  </RhTypography>
                </StyledBreakdown>
              ) : (
                <RhTypography>
                  {formatDollarsToCents(offerSnapshot.rhythmKwhRate, false)}
                  {tYourCurrentPlanSectionEnergyCharge}
                </RhTypography>
              )}
            </StyledFeature>
          </StyledFeaturesColumn>
          <StyledFeaturesColumn>
            <StyledFeature>{getRateInfo()}</StyledFeature>
            {!order.isMonthToMonth && (
              <WelcomePacketLink orderId={order.id} premiseId={premise.id}>
                <StyledFeature>
                  <PDFIcon
                    width={20}
                    height={20}
                    color={CSSVariables.COLOR_PRIMARY_MAIN}
                  />
                  <RhTypography>
                    {tYourCurrentPlanSectionYourContractDocuments}
                  </RhTypography>
                </StyledFeature>
              </WelcomePacketLink>
            )}
            <TOSLink tosUrl={order.currentRhythmTosLink(locale)}>
              <StyledFeature>
                <PDFIcon
                  width={20}
                  height={20}
                  color={CSSVariables.COLOR_PRIMARY_MAIN}
                />
                <RhTypography color="primary">
                  {tYourCurrentTermsOfService}
                </RhTypography>
              </StyledFeature>
            </TOSLink>
          </StyledFeaturesColumn>
        </StyledFeatures>
      </StyledBody>
    </StyledCard>
  );
};
