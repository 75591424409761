import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useProspectFromContext } from "@portal/components/ProspectProvider/useProspectFromContext";
import { SignUpBillingPreferencesForm } from "@portal/components/SignUpBillingPreferencesForm/SignUpBillingPreferencesForm";
import { SignUpPageLayout } from "@portal/components/SignUpPageLayout/SignUpPageLayout";
import { usePortalOfferSnapshotQuery } from "@portal/hooks/queries/usePortalOfferSnapshot.query";
import { enrollOffersPath } from "@portal/routes/routePaths";
import { Show500Page } from "@portal/utils/errors";
import React from "react";
import { useNavigate } from "react-router-dom";

export const SignUpBillingPreferencesPage = () => {
  const prospect = useProspectFromContext();
  const navigate = useNavigate();

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: prospect.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(prospect.offerSnapshotUuid),
    },
  });

  if (!prospect.offerSnapshotUuid) {
    navigate(enrollOffersPath(prospect.uuid));
  }

  if (offerSnapshotQuery.isError) {
    throw new Show500Page("Could not get selected offer");
  }

  return (
    <SignUpPageLayout>
      {offerSnapshotQuery.isPending ? (
        <RhCircularProgress />
      ) : (
        <SignUpBillingPreferencesForm
          offerSnapshot={offerSnapshotQuery.data}
          prospect={prospect}
        />
      )}
    </SignUpPageLayout>
  );
};
