import { BILL_COST_ESTIMATOR_SCROLL_TO_POINT } from "@portal/components/BillCostEstimator/BillCostEstimator.constants";
import { billCostEstimatorTranslations } from "@portal/components/BillCostEstimator/BillCostEstimator.en.i18n";
import {
  BillCostEstimatorContainer,
  BillCostEstimatorHeader,
  BillCostEstimatorSubtitle,
  BillCostEstimatorTitle,
} from "@portal/components/BillCostEstimator/BillCostEstimator.styled";
import { useModals } from "@portal/components/ModalsManager/useModals";
import { MonthlyUsageSliderV2 } from "@portal/components/MonthlyUsageSliderV2/MonthlyUsageSliderV2";
import { OfferSnapshotMiniCard } from "@portal/components/OfferSnapshotMiniCard/OfferSnapshotMiniCard";
import { RhOffersGrid } from "@portal/components/RhOffersGrid/RhOffersGrid";
import { useHandleEnrollNowClick } from "@portal/hooks/useHandleEnrollNowClick/useHandleEnrollNowClick";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React, { useCallback } from "react";

interface BillCostEstimatorProps {
  estimatedMonthlyUsage: number;
  id?: string;
  offerSnapshots: OfferSnapshot[];
  onEstimatedMonthlyUsageChange: (value: number) => void;
}

export const BillCostEstimator = (props: BillCostEstimatorProps) => {
  const handleEnrollNowClick = useHandleEnrollNowClick();
  const { addModal } = useModals();
  const {
    onEstimatedMonthlyUsageChange,
    offerSnapshots,
    estimatedMonthlyUsage,
    id = BILL_COST_ESTIMATOR_SCROLL_TO_POINT,
  } = props;
  const { translate } = useTranslations();

  const {
    tBillCostEstimatorOfferSnapshotLearnMoreModalSelectCta,
    tBillCostEstimatorTitle,
    tBillCostEstimatorSubtitle,
  } = translate(billCostEstimatorTranslations);

  const handleSelectPlanClick = useCallback(
    (offerSnapshot: OfferSnapshot) => {
      if (offerSnapshot.isThermostatOffer) {
        return addModal({
          offerSnapshotThermostatRequired: {
            offerSnapshot,
            onEnrollClick: () =>
              handleEnrollNowClick({
                estimatedMonthlyUsage,
                offerSnapshot,
              }),
          },
        });
      }

      return handleEnrollNowClick({
        estimatedMonthlyUsage,
        offerSnapshot,
      });
    },
    [estimatedMonthlyUsage, handleEnrollNowClick, addModal]
  );

  return (
    <BillCostEstimatorContainer id={id}>
      <BillCostEstimatorHeader>
        <BillCostEstimatorTitle variant="h2">
          {tBillCostEstimatorTitle}
        </BillCostEstimatorTitle>
        <BillCostEstimatorSubtitle variant="subtitle2">
          {tBillCostEstimatorSubtitle}
        </BillCostEstimatorSubtitle>
      </BillCostEstimatorHeader>
      <MonthlyUsageSliderV2
        onChange={(_, value) => {
          if (Array.isArray(value)) {
            return;
          }

          onEstimatedMonthlyUsageChange(value);
        }}
      />
      <RhOffersGrid offerCount={offerSnapshots.length} noPadding>
        {offerSnapshots.map((offerSnapshot) => {
          const handleLearnMoreClick = () => {
            addModal({
              offerSnapshotLearnMore: {
                offerSnapshotUUID: offerSnapshot.uuid,
                onEnrollClick: () => {
                  handleEnrollNowClick({
                    estimatedMonthlyUsage,
                    offerSnapshot,
                  });
                },
                selectPlanCtaText:
                  tBillCostEstimatorOfferSnapshotLearnMoreModalSelectCta,
              },
            });
          };

          return (
            <OfferSnapshotMiniCard
              key={offerSnapshot.id}
              onLearnMoreClick={handleLearnMoreClick}
              estimatedMonthlyUsage={estimatedMonthlyUsage}
              offerSnapshot={offerSnapshot}
              onSelectOfferSnapshot={(selectedOfferSnapshot: OfferSnapshot) => {
                handleSelectPlanClick(selectedOfferSnapshot);
              }}
            />
          );
        })}
      </RhOffersGrid>
    </BillCostEstimatorContainer>
  );
};
