import "dayjs/locale/es";

import { AppBrands } from "@common/enums/appBrands.enum";
import { EnvTypes } from "@common/enums/envTypes.enum";
import {
  getAppBrand,
  getEnv,
} from "@common/services/getEnvApplicationSettings.service";
import enBase from "@portal/translations/en-base.json";
import enEnergyTexas from "@portal/translations/en-energyTexas.json";
import enRhythm from "@portal/translations/en-rhythm.json";
import esEnergyTexas from "@portal/translations/es-energyTexas.json";
import esRhythm from "@portal/translations/es-rhythm.json";
import { generateTranslations } from "@portal/utils/generateTranslations.util";
import { captureException as sentryCaptureException } from "@sentry/react";
import dayjs from "dayjs";
import { IntlShape, createIntl } from "react-intl";

export const englishLanguageCode = "en";
export const spanishLanguageCode = "es";
export const energyTexasEnglishLanguageCode = "en-energyTexas";
export const energyTexasSpanishLanguageCode = "es-energyTexas";

export const AllSupportedPortalLanguages = [
  englishLanguageCode,
  spanishLanguageCode,
] as const;
export type SupportedPortalLanguage =
  (typeof AllSupportedPortalLanguages)[number];

export const translations: Record<string, Record<string, string>> = {
  [energyTexasEnglishLanguageCode]: {
    ...enBase,
    ...enRhythm,
    ...enEnergyTexas,
  },
  [energyTexasSpanishLanguageCode]: {
    ...enBase,
    ...esRhythm,
    ...esEnergyTexas,
  },
  [englishLanguageCode]: { ...enBase, ...enRhythm },
  [spanishLanguageCode]: { ...enBase, ...esRhythm },
};

export const findSupportedLanguage = (
  language: string = ""
): SupportedPortalLanguage => {
  // 0th index is language code, 1st index is nationality, e.g. es-MX
  const languageCode = language.split("-")[0];

  return (
    AllSupportedPortalLanguages.find(
      (supportedLanguage) => supportedLanguage === languageCode
    ) || englishLanguageCode
  );
};

const env = getEnv();

const appBrand = getAppBrand();

const getTranslationLocale = ({
  locale,
}: {
  locale: SupportedPortalLanguage;
}) => {
  if (locale === "en" && appBrand === AppBrands.ENERGY_TEXAS) {
    return energyTexasEnglishLanguageCode;
  }
  if (locale === "es" && appBrand === AppBrands.ENERGY_TEXAS) {
    return energyTexasSpanishLanguageCode;
  }
  if (locale === "es") {
    return spanishLanguageCode;
  }
  if (locale === "en") {
    return englishLanguageCode;
  }
  return englishLanguageCode;
};

export const generateIntl = (
  customerLanguagePreference?: SupportedPortalLanguage | string | null
): IntlShape => {
  const locale = findSupportedLanguage(
    customerLanguagePreference || navigator.language || ""
  );

  dayjs.locale(locale);
  generateTranslations(locale);

  const translationLocale = getTranslationLocale({ locale });

  return createIntl({
    locale,
    messages: translations[translationLocale],
    onError: (error) => {
      sentryCaptureException(error);
      if (env !== EnvTypes.production) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });
};
