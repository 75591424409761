import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useFormattedFeatures } from "@portal/components/OfferFeatures/useFormattedFeatures";
import {
  FeatureItem,
  FeatureList,
} from "@portal/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreFeatures/OfferSnapshotLearnMoreFeatures.styled";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotLearnMoreFeaturesProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreFeatures = (
  props: OfferSnapshotLearnMoreFeaturesProps
) => {
  const { offerSnapshot } = props;
  const features = useFormattedFeatures(offerSnapshot);

  return (
    <FeatureList>
      {features.map((feature) => {
        const { Icon, id, text } = feature;

        return (
          <FeatureItem key={id}>
            <Icon aria-hidden />
            <RhTypography variant="body1">{text}</RhTypography>
          </FeatureItem>
        );
      })}
    </FeatureList>
  );
};
