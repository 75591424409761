import { ReactComponent as TandemBikeLeft } from "@portal/images/TandemBikeLeft.svg";
import { ReactComponent as TandemBikeRight } from "@portal/images/TandemBikeRight.svg";
import {
  StyledPageBackground,
  StyledTandemBikeLeft,
  StyledTandemBikeRight,
} from "@portal/styled/portalPageLayout.styled";
import React from "react";

export const EnrollOffersPageBackground = () => {
  return (
    <>
      <StyledTandemBikeLeft>
        <TandemBikeLeft />
      </StyledTandemBikeLeft>
      <StyledTandemBikeRight>
        <TandemBikeRight />
      </StyledTandemBikeRight>
      <StyledPageBackground />
    </>
  );
};
