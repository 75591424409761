import { IdType, UUIDType } from "@common/types/apiTypes";
import { rhWindow } from "@common/utils/rhWindow";
import { MARKETING_RHYTHM_URL } from "@portal/constants/urls.constant";
import { Utm, UtmMedium, UtmSource } from "@portal/constants/utm.constant";

export const SIGN_IN_PATHNAME = "sign-in";
export const SIGN_OUT_PATHNAME = "sign-out";
export const SIGN_UP_PATHNAME = "sign-up";
export const MY_ACCOUNT_PATHNAME = "account";
export const PRODUCT_ADDONS_OPT_IN_PATHNAME = "additional-services";
export const NOTIFICATIONS_PATHNAME = "notifications";
export const RENEWALS_PATHNAME = "renew";
export const REWARDS_PATHNAME = "rewards";
export const PAY_BILL_PATHNAME = "pay-bill";
export const PAYMENT_EXTENSION_PATHNAME = "payment-extension";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
// Not really a path but cannot think of a better place to put this url in
export const FAQ_HOME_URL = "https://support.gotrhythm.com/hc";
export const REWARDS_FAQ_URL = `${FAQ_HOME_URL}/en-us/articles/360052366771-Rhythm-Rewards`;
export const SOLAR_BUYBACK_FAQ_URL = `${MARKETING_RHYTHM_URL}/solar-buyback-plan-faq`;

export const HOME = "home";
export const WELCOME_PATHNAME = "welcome";
export const CREATE_PASSWORD = "create-password";
export const EXPIRED_PASSWORD = "expired-password";

export const REFERRAL_CODE_QUERY_PARAM = "referralCode";

export const DEVICES_PATHNAME = "devices";

export const BILL_BREAKDOWN_PATHNAME = "bill-breakdown";

export enum SignUpStepNames {
  Address = "address",
  Availability = "availability",
}

export const SignUpSteps = [
  "address",
  "billing-preferences",
  "call-us",
  "confirm-address",
  "contact",
  "credit-check",
  "deposit",
  "details",
  "information",
  "name",
  "payment",
  "summary",
  "add-ons",
  "provide-additional-information",
] as const;

export type SignUpStepType = (typeof SignUpSteps)[number];

export const SIGN_UP_RESUME_SUB_PATHNAME = "welcome-back";
export type SignUpResumeStepType = typeof SIGN_UP_RESUME_SUB_PATHNAME;

export const SIGN_UP_LEAD_GEN_SUB_PATHNAME = "lead-generation";
export type SignUpLeadGenStepType = typeof SIGN_UP_LEAD_GEN_SUB_PATHNAME;

export const DevicesEnrollSteps = [
  "choose-your-device-brand",
  "terms-and-conditions",
  "select-devices",
  "summary",
] as const;
export type DevicesEnrollStepType = (typeof DevicesEnrollSteps)[number];

const addDomain = (path: string): string =>
  `${rhWindow.location.origin}${path}`;

export const signInPath = () => {
  return `/${SIGN_IN_PATHNAME}`;
};

export const signOutPath = () => {
  return `/${SIGN_OUT_PATHNAME}`;
};

export const signUpBasePath = () => {
  return `/${SIGN_UP_PATHNAME}`;
};

export const signUpUrl = () => {
  return addDomain(signUpBasePath());
};

export const signUpReferralUrl = (referralCode: string) => {
  const queryParams = new URLSearchParams({
    [REFERRAL_CODE_QUERY_PARAM]: referralCode,
    [Utm.SOURCE]: UtmSource.RAF,
    [Utm.MEDIUM]: UtmMedium.MY_ACCOUNT,
  });

  return `${MARKETING_RHYTHM_URL}/raf?${queryParams.toString()}`;
};

export const signUpStepPath = (
  step: SignUpStepType | SignUpResumeStepType | SignUpLeadGenStepType
) => {
  return `${signUpBasePath()}/${step}`;
};

export const signUpResumePath = (uuid?: UUIDType) => {
  return `/${signUpBasePath()}/${SIGN_UP_RESUME_SUB_PATHNAME}/${uuid}`;
};

export const signUpLeadGenPath = (uuid?: UUIDType) => {
  return `/${signUpBasePath()}/${SIGN_UP_LEAD_GEN_SUB_PATHNAME}/${uuid}`;
};

export const customerHomePath = () => {
  return `/${HOME}`;
};

export const billBreakdownPath = (invoiceId: IdType) => {
  return `/${BILL_BREAKDOWN_PATHNAME}/${invoiceId}`;
};

export const myAccountPath = () => {
  return `/${MY_ACCOUNT_PATHNAME}`;
};

export const productAddOnsOptInPath = () => {
  return `/${PRODUCT_ADDONS_OPT_IN_PATHNAME}`;
};

export const payBillPath = () => {
  return `/${PAY_BILL_PATHNAME}`;
};

export const paymentExtensionPath = () => {
  return `/${PAYMENT_EXTENSION_PATHNAME}`;
};

export const rewardsPath = () => {
  return `/${REWARDS_PATHNAME}`;
};

export const notificationsPath = () => {
  return `/${NOTIFICATIONS_PATHNAME}`;
};

export const renewalsPath = () => {
  return `/${RENEWALS_PATHNAME}`;
};

export const renewalsSummaryPath = () => {
  return `/${RENEWALS_PATHNAME}/summary`;
};

export const faqUrl = (subPage?: string) => {
  // For now, faq is only in english for US, once we add spanish, we need to change the hard coded en-us
  const localeUri = "en-us";
  const base = `${FAQ_HOME_URL}/${localeUri}`;

  if (subPage) {
    return `${base}/${subPage}`;
  }
  return base;
};

export const payBillSuccessPath = () => {
  return `/${PAY_BILL_PATHNAME}/success`;
};

export const oneClickRenewalsPath = (renewalToken: string) => {
  return `/${RENEWALS_PATHNAME}/${renewalToken}`;
};

export const oneClickRenewalsSummaryPath = (renewalToken: string) => {
  return `/${RENEWALS_PATHNAME}/${renewalToken}/summary`;
};

export const activatePath = (activationToken: string) => {
  return `/${WELCOME_PATHNAME}/${activationToken}`;
};

export const createPasswordPath = () => {
  return `/${CREATE_PASSWORD}`;
};

export const expiredPasswordPath = () => {
  return `/${EXPIRED_PASSWORD}`;
};

export const forgotPasswordPath = () => {
  return `/${FORGOT_PASSWORD}`;
};

export const resetPasswordPath = (recoveryToken: string) => {
  return `/${RESET_PASSWORD}/${recoveryToken}`;
};

export const homePath = (): string => {
  return "/";
};

export const accountCreationPendingPath = (): string => {
  return "/account-creation-pending";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const quickPayBasePath = () => {
  return "/quick-pay-one-click";
};

export const quickPayPath = (token: string) => {
  return `${quickPayBasePath()}/${token}`;
};

export const quickPayConfirmationPath = () => {
  return `${quickPayBasePath()}/confirmation`;
};

export const guestPayBasePath = () => {
  return "/quick-pay";
};

export const guestPayAccountLookUpPath = () => {
  return guestPayBasePath();
};

export const guestPayAccountSummaryPath = () => {
  return `${guestPayAccountLookUpPath()}/account`;
};

export const guestPayConfirmationPath = () => {
  return `${guestPayAccountLookUpPath()}/confirmation`;
};

export const enrollBasePath = () => {
  return "/enroll";
};

export const enrollOffersPath = (prospectUuid?: UUIDType) => {
  return `${enrollBasePath()}/offers/${prospectUuid || ""}`;
};

export const devicesBasePath = () => `/${DEVICES_PATHNAME}`;

export const devicesEnrollPath = () => `${devicesBasePath()}/enroll`;

export const devicesEnrollStepPath = (step: DevicesEnrollStepType) =>
  `${devicesEnrollPath()}/${step}`;

export const devicesEnrollChooseYourBrandDeviceStep =
  (): DevicesEnrollStepType => "choose-your-device-brand";
export const devicesEnrollTermsStep = (): DevicesEnrollStepType =>
  "terms-and-conditions";
export const devicesEnrollSelectDevicesStep = (): DevicesEnrollStepType =>
  "select-devices";
export const devicesEnrollSummaryStep = (): DevicesEnrollStepType => "summary";
