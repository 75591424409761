import {
  StyledHasIcon,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from "@portal/components/OfferFeatures/OfferFeatures.styled";
import { useFormattedFeatures } from "@portal/components/OfferFeatures/useFormattedFeatures";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React from "react";

interface OfferFeaturesProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferFeatures = ({ offerSnapshot }: OfferFeaturesProps) => {
  const features = useFormattedFeatures(offerSnapshot);

  return (
    <StyledList dense>
      {features.map(({ Icon, text, id }) => (
        <StyledListItem key={id}>
          <StyledHasIcon>
            <Icon />
          </StyledHasIcon>
          <StyledListItemText primary={text} />
        </StyledListItem>
      ))}
    </StyledList>
  );
};
