import { IdType, UUIDType } from "@common/types/apiTypes";
import { CompetitorPlansParams } from "@portal/api/competitorApi";
import { DeviceBrands } from "@portal/types/devicesTypes";
import { OfferSnapshotsSearchOptions } from "@portal/types/offerSnapshotTypes";
import { ProductContractStatusFilter } from "@portal/types/productContractTypes";
import { ResideoOauthType } from "@portal/types/resideoOauthTypes";

export const ProductContractQueryKeys = {
  claims: (premiseId: IdType, productContractId: UUIDType) =>
    [
      "premise",
      premiseId,
      "product-contract",
      productContractId,
      "claims",
    ] as const,
  list: {
    DEFAULT: (premiseId: IdType) =>
      ["premise", premiseId, "product-contract"] as const,
    active: (premiseId: IdType, contractIds: UUIDType[] = []) =>
      [
        ...ProductContractQueryKeys.list.DEFAULT(premiseId),
        ProductContractStatusFilter.ACTIVE,
        { contractIds },
      ] as const,
    expired: (premiseId: IdType) =>
      [
        ...ProductContractQueryKeys.list.DEFAULT(premiseId),
        ProductContractStatusFilter.EXPIRED,
      ] as const,
  },
};

export const PremiseQueryKeys = {
  BASE: ["premise"] as const,
  accountSummary: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "accountSummary",
  ],
  allRenewableOfferSnapshots: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "allRenewableOfferSnapshots",
  ],
  attachedDevices: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "attachedDevices",
  ],
  billForecastSummary: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "billForecastSummary",
  ],
  billingStatus: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "billingStatus",
  ],
  documents: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "documents",
  ],
  energyInvoices: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "invoices",
    "list-energy",
  ],
  fetchPaymentMethod: (premiseId: IdType, paymentMethodId: string) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "fetchPaymentMethod",
    paymentMethodId,
  ],
  invoiceHistory: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "invoiceHistory",
  ],
  kwhUsageDay: (premiseId: IdType, date: string) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "kwhUsageDay",
    date,
  ],
  kwhUsageSummary: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "kwhUsageSummary",
  ],
  paymentExtensionsActive: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "paymentExtension",
    "active",
  ],
  paymentExtensionsQualified: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "paymentExtension",
    "qualified",
  ],
  paymentHistory: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "paymentHistory",
  ],
  paymentMethods: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "paymentMethods",
  ],
  renewalOfferSnapshots: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "renewalOfferSnapshots",
  ],
  retrieve: (premiseId: IdType) =>
    [...PremiseQueryKeys.BASE, premiseId] as const,
  temperatureSummary: (premiseId: IdType) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "temperatureSummary",
  ],
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) => [
    ...PremiseQueryKeys.BASE,
    premiseId,
    "thermostatConnectionActivity",
    searchOptions,
  ],
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) =>
    [
      ...PremiseQueryKeys.BASE,
      premiseId,
      "thermostatDemandResponseActivity",
      searchOptions,
    ] as const,
};

export const ProductQueryKeys = {
  BASE: ["products"] as const,
  tiers: {
    BASE: () => [...ProductQueryKeys.BASE, "tiers"] as const,
    list: () => [...ProductQueryKeys.tiers.BASE(), "list"] as const,
  },
};

export const SignUpQueryKeys = {
  BASE: ["signUp"] as const,
  products: {
    tiers: {
      BASE: () => [...SignUpQueryKeys.BASE, "tiers"] as const,
      list: () => [...SignUpQueryKeys.products.tiers.BASE(), "list"],
    },
  },
};

export const CompetitorQueryKeys = {
  plans: (options?: CompetitorPlansParams) =>
    ["competitor-intel", "competitor-plans", options] as const,
};

export const QuickPayQueryKeys = {
  BASE: ["quickPay"] as const,
  accountBalance: (token: UUIDType) =>
    [...QuickPayQueryKeys.BASE, token, "accountBalance"] as const,
  paymentMethod: (token: UUIDType, paymentMethodId: string) =>
    [
      ...QuickPayQueryKeys.BASE,
      token,
      "paymentMethod",
      paymentMethodId,
    ] as const,
  paymentMethods: (token: UUIDType) =>
    [...QuickPayQueryKeys.BASE, token, "paymentMethods"] as const,
  retrieve: (token: UUIDType) => [...QuickPayQueryKeys.BASE, token] as const,
};

export const GuestPayQueryKeys = {
  BASE: ["guestPay"] as const,
  accountBalance: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "accountBalance"] as const,
  getPaymentMethod: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "paymentMethod"] as const,
  updatePaymentMethod: (token: UUIDType) =>
    [...GuestPayQueryKeys.BASE, token, "updatePaymentMethod"] as const,
};

export const PricingQueryKeys = {
  offerSnapshot: {
    list: (searchOptions?: OfferSnapshotsSearchOptions) => [
      "offer-snapshots",
      searchOptions,
    ],
    retrieve: (offerSnapshotId: IdType) => [
      "offerSnapshot",
      offerSnapshotId,
      "details",
    ],
  },
};

export const PortalQueryKeys = {
  oneClickRenewal: (renewalToken: string) => [renewalToken, "oneClickRenewal"],
  oneClickRenewalDefaultPaymentMethod: (renewalToken: string) => [
    renewalToken,
    "defaultPaymentMethod",
  ],
  oneClickRenewalOfferSnapshots: (renewalToken: string) => [
    renewalToken,
    "oneClickRenewalOfferSnapshots",
  ],
  utilities: {
    search: (options: OfferSnapshotsSearchOptions) => [
      "utilities",
      "search",
      options,
    ],
  },
};

export const AcquisitionCampaignKeys = {
  BASE: "acquisition-campaigns",
  retrieve: (slug: string) => [AcquisitionCampaignKeys.BASE, slug],
};

export const customerQueryKeys = {
  BASE: ["customers"] as const,
  deviceEligibilityInformation: (customerId: IdType) => [
    ...customerQueryKeys.BASE,
    customerId,
    "deviceEligibilityInformation",
  ],
  getResideoOauthUrl: (deviceBrand: DeviceBrands | null) =>
    [...customerQueryKeys.BASE, deviceBrand] as const,
  listResideoDevices: ({ oauthCode, deviceBrand }: ResideoOauthType) => [
    ...customerQueryKeys.BASE,
    oauthCode,
    deviceBrand,
  ],
  me: () => [...customerQueryKeys.BASE, "me"] as const,
};

export const InvoiceQueryKeys = {
  BASE: ["invoices"],
  compare: (invoiceId: IdType) => [
    ...InvoiceQueryKeys.BASE,
    invoiceId,
    "bill-explanation",
    "compare",
  ],
  factors: (invoiceId: IdType) => [
    ...InvoiceQueryKeys.BASE,
    invoiceId,
    "bill-explanation",
    "factors",
  ],
  orders: (invoiceId: IdType) => [
    ...InvoiceQueryKeys.BASE,
    invoiceId,
    "orders",
  ],
  retrieve: (invoiceId: IdType) => [...InvoiceQueryKeys.BASE, invoiceId],
  temperature: (invoiceId: IdType) => [
    ...InvoiceQueryKeys.BASE,
    invoiceId,
    "bill-explaination",
    "temperature",
  ],
  usage: (invoiceId: IdType) => [
    ...InvoiceQueryKeys.BASE,
    invoiceId,
    "bill-explaination",
    "usage",
  ],
};
