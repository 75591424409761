import { convert24HourTo12Hour } from "@common/utils/timeHelpers";
import {
  StyledChart,
  StyledChartTier,
  StyledChartTime,
  StyledPriceScheduleBreakdownVisual,
} from "@portal/components/PriceScheduleBreakdown/PriceScheduleBreakdownVisual/PriceScheduleBreakdownVisual.styled";
import { PriceScheduleBreakdownVisualType } from "@portal/types/priceScheduleBreakdownVisualTypes";
import classNames from "classnames";
import React from "react";

interface PriceScheduleBreakdownVisualProps {
  className?: string;
  isTouGeneration?: boolean;
  priceScheduleBreakdown: PriceScheduleBreakdownVisualType;
}

export const PriceScheduleBreakdownVisual = ({
  isTouGeneration,
  priceScheduleBreakdown,
  className,
}: PriceScheduleBreakdownVisualProps) => (
  <StyledPriceScheduleBreakdownVisual className={className}>
    <StyledChart>
      {priceScheduleBreakdown.hoursBreakdown.map((hour) => {
        const { start, end, color } = hour;
        let actualEnd = end;

        // If last hour, we need to add one hour to our end time (which is 23), in order to
        // correctly calculate the last breakdown section as midnight (24) minus x
        if (end === 23) {
          actualEnd = 24;
        }

        const length = actualEnd - start;
        const lengthPercentageOfDay = (length / 24) * 100;

        return (
          <StyledChartTier
            key={start}
            className={classNames({ timeTop: length <= 2 })}
            style={{
              backgroundColor: `${color}`,
              flexBasis: `${lengthPercentageOfDay}%`,
            }}
            $isTouGeneration={isTouGeneration}
          >
            <StyledChartTime>{convert24HourTo12Hour(start)}</StyledChartTime>
          </StyledChartTier>
        );
      })}
    </StyledChart>
  </StyledPriceScheduleBreakdownVisual>
);
