import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import {
  StyledNavIconAnchor,
  StyledSignUpLink,
} from "@design-system/components/RhHeader/RhHeader.styled";
import { ReactComponent as PhoneIcon } from "@design-system/icons/PhoneIcon.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { CartDesktop } from "@portal/components/CartDesktop/CartDesktop";
import { loggedOutHeaderTranslations } from "@portal/components/LoggedOutHeader/LoggedOutHeader.en.i18n";
import { PortalHeaderLogo } from "@portal/components/PortalHeaderLogo/PortalHeaderLogo";
import { RHYTHM_GENERAL_SALES_PHONE_NUMBER } from "@portal/constants/misc.constant";
import { useIsCartSignUpStep } from "@portal/hooks/useIsCartSignUpStep";
import { useSignUpFlow } from "@portal/hooks/useSignUpFlow";
import { useTranslations } from "@portal/hooks/useTranslations";
import {
  enrollBasePath,
  enrollOffersPath,
  signUpBasePath,
} from "@portal/routes/routePaths";
import { selectSignUpState } from "@portal/selectors/signUpSelectors";
import { ActionType } from "@portal/services/segment.service";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const LoggedOutHeader = () => {
  const { pathname } = useLocation();
  const { translate } = useTranslations();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const isInSignUp =
    pathname.includes(signUpBasePath()) || pathname.includes(enrollBasePath());
  const isCartStep = useIsCartSignUpStep();
  const { trackEvent } = useSignUpFlow();
  const {
    acquisition: { acquisitionTfn },
  } = useSelector(selectSignUpState);

  const { tLoggedOutHeaderSignUp } = translate(loggedOutHeaderTranslations);

  const onPhoneNumberClick = () => {
    trackEvent({
      action: ActionType.click,
      category: "page.top-nav",
      label: "phoneNumber",
      value: acquisitionTfn || RHYTHM_GENERAL_SALES_PHONE_NUMBER,
    });
  };

  const cart = isInSignUp && isCartStep && !isMobile ? <CartDesktop /> : null;

  const phoneNumber =
    isInSignUp && acquisitionTfn ? (
      <StyledNavIconAnchor
        key="phoneNumber"
        href={`tel:${acquisitionTfn}`}
        onClick={onPhoneNumberClick}
      >
        <PhoneIcon />
        {acquisitionTfn}
      </StyledNavIconAnchor>
    ) : null;

  const signUpLink = (
    <StyledSignUpLink key={enrollOffersPath()} to={enrollOffersPath()}>
      {tLoggedOutHeaderSignUp}
    </StyledSignUpLink>
  );

  const links = [];

  if (!isInSignUp) {
    links.push(signUpLink);
  }

  return (
    <RhHeader
      items={links}
      logo={<PortalHeaderLogo />}
      phone={phoneNumber}
      cart={cart}
    />
  );
};
