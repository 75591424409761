import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { miniOfferSnapshotCardTranslations } from "@portal/components/MiniOfferSnapshotCard/MiniOfferSnapshotCard.en.i18n";
import {
  MiniCard,
  PlanContainer,
  RhythmLearnMoreButton,
} from "@portal/components/MiniOfferSnapshotCard/MiniOfferSnapshotCard.styled";
import { useTranslations } from "@portal/hooks/useTranslations";
import { OfferSnapshot } from "@portal/models/OfferSnapshot.model";
import React from "react";

interface MiniOfferSnapshotCardProps {
  className?: string;
  learnMoreButtonOnClick: () => void;
  offerSnapshot: OfferSnapshot;
  submitButtonOnClick: () => void;
  submitButtonText: string;
}

export const MiniOfferSnapshotCard = (props: MiniOfferSnapshotCardProps) => {
  const {
    offerSnapshot,
    submitButtonOnClick,
    submitButtonText,
    learnMoreButtonOnClick,
    className,
  } = props;

  const { translate, translateJsx } = useTranslations();

  const { tMiniOfferSnapshotCardLearnMore } = translate(
    miniOfferSnapshotCardTranslations
  );
  const { tMiniOfferSnapshotCardPerKwh } = translateJsx({
    tMiniOfferSnapshotCardPerKwh: {
      price2000: formatDollarsToCents(offerSnapshot.price2000Kwh),
    },
  });

  return (
    <MiniCard className={className}>
      <PlanContainer>
        <RhTypography variant="body1" fontWeight="Bold">
          {offerSnapshot.title}
        </RhTypography>
        <RhTypography variant="body2" fontWeight="Bold" color="textSecondary">
          {tMiniOfferSnapshotCardPerKwh}
        </RhTypography>

        <RhythmLearnMoreButton onClick={learnMoreButtonOnClick}>
          {tMiniOfferSnapshotCardLearnMore}
        </RhythmLearnMoreButton>
      </PlanContainer>

      <div>
        <RhButton
          data-tracking-click={{
            event: "Prospect selected plan",
          }}
          size="small"
          onClick={submitButtonOnClick}
        >
          {submitButtonText}
        </RhButton>
      </div>
    </MiniCard>
  );
};
