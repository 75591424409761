import { featureFlagsApi } from "@common/api/featureFlagsApi";
import { FeatureFlagMetric } from "@common/context/featureFlagClient.enums";
import { useScreenResolution } from "@common/hooks/useScreenResolution";
import { DefaultGlobalAnnouncement } from "@common/types/globalAnnouncementTypes";
import { isBot } from "@common/utils/isBot";
import { captureException, withScope } from "@sentry/react";
import { decamelize } from "humps";
import isEqual from "lodash/isEqual";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

/* Feature Flag Context */
type ValidFlagValueTypes = boolean | string;

export type ValidFlagValues =
  | ValidFlagValueTypes
  | Record<string, ValidFlagValueTypes>;

export type FeatureFlagSet = Record<string, ValidFlagValues>;

export interface FetchedFeatureFlags {
  flags: FeatureFlagSet;
}

export interface FeatureFlagValues {
  opsAlloyValidationReport: boolean;
  opsBillBreakdownGraph: boolean;
  opsDepositAlternativeProgram: boolean;
  opsEnergyOnlyDeferPaymentPlan: boolean;
  opsFidelityCashPayments: boolean;
  opsPendingProductContracts: boolean;
  opsProductAddOns: boolean;
  opsProductAddOnsEnrollment: boolean;
  opsProductContractClaims: boolean;
  opsRenewalWizard: boolean;
  opsReprocessInvoice: boolean;
  opsShowViewTosLink: boolean;
  opsUsageChart: boolean;
  opsUsageGraphTemperatures: boolean;
  opsZuoraAch: boolean;
  portalAcquisitionSlugTfn: boolean;
  portalAddAch: boolean;
  portalAverageBilling: boolean;
  portalBillBreakdownGraph: boolean;
  portalBillingHistoryV2: boolean;
  portalCondensedMobileOfferCard: boolean;
  portalDepositAlternativeProgram: boolean;
  portalDeviceManagement: boolean;
  portalDeviceManagementAmazon: boolean;
  portalDeviceManagementHoneywell: boolean;
  portalEstimatedAnnualSavingsTag: boolean;
  portalGlobalAnnouncement: DefaultGlobalAnnouncement;
  portalGoogleAuth: boolean;
  portalNewPlansPage: boolean;
  portalOptOutProductContract: boolean;
  portalPickYourDueDate: boolean;
  portalProductAddOns: boolean;
  portalProductAddOnsOptIn: boolean;
  portalProductAddOnsSignUpFlow: boolean;
  portalProductContractClaimsManagement: boolean;
  portalRenewalAutopayToggle: boolean;
  portalRenewalPaperlessToggle: boolean;
  portalRenewalsCompetitorPlanChart: boolean;
  portalRepPriceComparison: boolean;
  portalShowViewTosLink: boolean;
  portalSolarBuybackUsageChart: boolean;
  portalSolarPlanShowAveragePrice: boolean;
  portalTouGenerationUsageGraph: boolean;
  portalUsageChart: boolean;
  portalUsageGraphTemperatures: boolean;
  portalZuoraAch: boolean;
  portalZuoraAchDigitalEnrollment: boolean;
  pricingNewCampaignsPage: boolean;
  pricingOffersBulkUpdate: boolean;
  pricingSolarBuybackDifferentiated: boolean;
}

export interface RhFlag {
  label: keyof FeatureFlagValues;
  recordVisitor(): void;
  value: ValidFlagValues;
}

export type RhFlags = {
  [key in keyof FeatureFlagValues]: RhFlag;
};

export interface FeatureFlagClient {
  getFlags: (
    flagNames: [keyof FeatureFlagValues, ValidFlagValues][]
  ) => RhFlags;
  isFetchingFlags: () => boolean;
  sendIdentifyEvent: (custom: Record<string, unknown>) => Promise<boolean>;
  trackMetric: (metric: FeatureFlagMetric) => Promise<void>;
}

export interface FeatureFlagManager {
  featureFlagClient: FeatureFlagClient;
}

export const FeatureFlagContext = createContext({} as FeatureFlagManager);

interface FeatureFlagClientProviderProps {
  children: React.ReactNode;
  featureFlagUserId?: string | null;
  sendEvents?: boolean;
}

const recordFlagNotFound = (flagName: string, kebabFlagName: string) => {
  // eslint-disable-next-line no-console
  console.error(`Unable to find flag ${kebabFlagName}`);

  withScope((scope) => {
    const errorToCapture = new Error("Launchdarkly flag not found");
    const errorData = {
      errorDescription: `Unable to find ${kebabFlagName} flag, represented in React as '${flagName}'`,
    };

    errorToCapture.name = "Invalid Launchdarkly Flag Name";
    scope.setContext("Launchdarkly Flag Error", errorData);
    captureException(errorToCapture);
  });
};

const checkAllFlagsExistsInLaunchDarkly = (
  fetchedFlags: FeatureFlagSet,
  flagNames: (keyof FeatureFlagValues)[]
) => {
  const flagNamesReturnedFromLaunchDarkly = Object.keys(fetchedFlags);

  flagNames.forEach((flagName: string) => {
    const kebabCaseFlagName = decamelize(flagName, {
      separator: "-",
    });

    if (!flagNamesReturnedFromLaunchDarkly.includes(flagName)) {
      recordFlagNotFound(flagName, kebabCaseFlagName);
    }
  });
};

const fetchAllFlags = async (
  featureFlagUserId: string,
  custom: Record<string, unknown>,
  setIsFetchingFlags: (isFetching: boolean) => void
): Promise<FetchedFeatureFlags> => {
  setIsFetchingFlags(true);
  try {
    const allFlags = await featureFlagsApi.flags({
      custom,
      key: featureFlagUserId,
    });

    if (allFlags?.flags) {
      return allFlags;
    }

    return { flags: {} };
  } catch {
    return { flags: {} };
  } finally {
    setIsFetchingFlags(false);
  }
};

const sendTrackMetric = async (
  featureFlagUserId: string,
  metric: FeatureFlagMetric
): Promise<boolean> => {
  try {
    await featureFlagsApi.metric.track(featureFlagUserId, metric);

    return true;
  } catch {
    return false;
  }
};

let firstTimeIdentify = true;

export const FeatureFlagClientProvider = ({
  children,
  featureFlagUserId,
  sendEvents = true,
}: FeatureFlagClientProviderProps) => {
  const [fetchedFlags, setFetchedFlags] = useState<FeatureFlagSet>({});
  const [fetchingFlags, setFetchingFlags] = useState<boolean>(true);
  const screenResolution = useScreenResolution();
  const sendFeatureFlagEvents = !isBot() && featureFlagUserId && sendEvents;
  const [currentCustomIdentifyProps, setCurrentCustomIdentifyProps] = useState<
    Record<string, unknown>
  >({});

  const sendIdentifyEvent = useCallback(
    async (custom?: Record<string, unknown>): Promise<boolean> => {
      if (featureFlagUserId) {
        try {
          const newCustomIdentifyProps = {
            ...currentCustomIdentifyProps,
            screenResolution,
            ...custom,
          };

          // Avoid creating new currentCustomIdentifyProps when unnecessary,
          // otherwise, this will trigger an unnecessary fetch flags event
          if (
            firstTimeIdentify ||
            !isEqual(currentCustomIdentifyProps, newCustomIdentifyProps)
          ) {
            firstTimeIdentify = false;
            await featureFlagsApi.user.identify({
              custom: newCustomIdentifyProps,
              key: featureFlagUserId,
            });

            setCurrentCustomIdentifyProps(() => newCustomIdentifyProps);

            return true;
          }
        } catch (error) {
          return false;
        }
      }

      return Promise.resolve(false);
    },
    [currentCustomIdentifyProps, featureFlagUserId, screenResolution]
  );

  // Identify user
  useEffect(() => {
    if (sendFeatureFlagEvents) {
      (async () => {
        const identified = await sendIdentifyEvent(currentCustomIdentifyProps);

        if (identified) {
          // eslint-disable-next-line no-console
          console.info(
            `[Feature flags] - Feature flag user (${featureFlagUserId}) identified successfully.`
          );
        }
      })();
    }
  }, [
    currentCustomIdentifyProps,
    sendFeatureFlagEvents,
    sendIdentifyEvent,
    featureFlagUserId,
  ]);

  // Fetch all flags
  useEffect(() => {
    if (sendFeatureFlagEvents) {
      (async () => {
        const allFlags = await fetchAllFlags(
          featureFlagUserId,
          currentCustomIdentifyProps,
          setFetchingFlags
        );

        setFetchedFlags(allFlags.flags);
      })();
    }
  }, [sendFeatureFlagEvents, featureFlagUserId, currentCustomIdentifyProps]);

  const sendRecordVisitor = useCallback(
    async (flagName: string): Promise<boolean> => {
      const kebabCaseFlagName = decamelize(flagName, {
        separator: "-",
      });

      if (featureFlagUserId) {
        try {
          await featureFlagsApi.user.recordVisitor(kebabCaseFlagName, {
            custom: currentCustomIdentifyProps,
            key: featureFlagUserId,
          });

          return true;
        } catch {
          return false;
        }
      }

      return Promise.resolve(false);
    },
    [currentCustomIdentifyProps, featureFlagUserId]
  );

  const getFlags = useCallback(
    (flagNames: [keyof FeatureFlagValues, ValidFlagValues][]) => {
      if (Object.keys(fetchedFlags).length) {
        checkAllFlagsExistsInLaunchDarkly(
          fetchedFlags,
          flagNames.map(([flagName]) => flagName)
        );
      }

      return flagNames.reduce<RhFlags>(
        (accumulator, [currentFlag, currentDefault]) => {
          const currentValue = fetchedFlags[currentFlag];
          let rhFlag: RhFlag;

          if (!sendFeatureFlagEvents || currentValue === undefined) {
            rhFlag = {
              label: currentFlag,
              recordVisitor: () => {},
              value: currentDefault,
            };
          } else {
            rhFlag = {
              label: currentFlag,
              recordVisitor: async () => {
                const flagRecorded = await sendRecordVisitor(currentFlag);

                if (flagRecorded) {
                  // eslint-disable-next-line no-console
                  console.info(
                    `[Feature flags] - Feature flag (${currentFlag}) recorded successfully for user (${featureFlagUserId})`
                  );
                }
              },
              value: currentValue,
            };
          }

          return {
            ...accumulator,
            [currentFlag]: rhFlag,
          };
        },
        {} as RhFlags
      );
    },
    [featureFlagUserId, fetchedFlags, sendFeatureFlagEvents, sendRecordVisitor]
  );

  const trackMetric = useCallback(
    async (metric: FeatureFlagMetric) => {
      if (sendFeatureFlagEvents) {
        const sentMetric = await sendTrackMetric(featureFlagUserId, metric);

        if (sentMetric) {
          // eslint-disable-next-line no-console
          console.info(
            `[Feature flags] - metric (${metric}) sent successfully for user (${featureFlagUserId})`
          );
        }
      }
    },
    [featureFlagUserId, sendFeatureFlagEvents]
  );

  const isFetchingFlags = useCallback((): boolean => {
    return fetchingFlags;
  }, [fetchingFlags]);

  const featureFlagClient = useMemo(
    () => ({
      featureFlagClient: {
        getFlags,
        isFetchingFlags,
        sendIdentifyEvent,
        trackMetric,
      },
    }),
    [getFlags, trackMetric, sendIdentifyEvent, isFetchingFlags]
  );

  return (
    <FeatureFlagContext.Provider value={featureFlagClient}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
