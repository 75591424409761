import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  LOGGED_OUT_TOP_MARGIN,
  LOGGED_OUT_TOP_MARGIN_MOBILE,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledBaseErrorPageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rhSpacingPx(LOGGED_OUT_TOP_MARGIN_MOBILE)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin-top: ${rhSpacingPx(LOGGED_OUT_TOP_MARGIN)};
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    flex-direction: row;
  }
`;

export const StyledIllustrationContainer = styled.div<{
  $withIllustration: boolean;
}>`
  margin-right: ${({ $withIllustration }) =>
    $withIllustration ? rhSpacingPx(7) : "inherit"};

  @media (max-width: ${RhythmBreakpoints.XS}px) {
    & > svg {
      max-height: ${rhSpacingPx(22)};
    }
    margin-bottom: ${rhSpacingPx(3)};
    margin-right: 0;
  }
`;

export const StyledBodyContainer = styled.div<{ $withIllustration: boolean }>`
  flex: 1;
  text-align: ${({ $withIllustration }) =>
    $withIllustration ? "left" : "center"};
  width: 350px;

  @media (max-width: ${RhythmBreakpoints.XS}px) {
    text-align: center;
  }
`;

export const StyledErrorCode = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_300};
    margin-bottom: ${rhSpacingPx(1)};
  }
`;

export const StyledErrorTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(2)};
  }
`;

export const StyledErrorMessage = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacingPx(4)};
  }
`;

export const StyledReturnHomeLink = styled(RhAnchor)`
  font-size: 16px;
`;
